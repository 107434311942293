angular

  .module("Milestone")

  .directive('phoneTrigger', [ "$rootScope","device", function($rootScope, device) {
    'use strict';
    return function(scope, elem, attrs) {

      $(window).on('load resize', function(){
        if(device.check() == 'phone'){
          elem.click();
        }
      })

    };
  }])
;
