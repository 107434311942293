(function() {

  'use strict';
  /* global angular, _RegionDataCache */

  angular
    .module("Milestone")

    .service('RegionDataCache', function() {

      var data = {
        region: null
      };

      if (typeof _RegionDataCache !== 'undefined') {
        data.region = _RegionDataCache.region;
      }

      return {
        region: data.region
      };

    })

  ;

})();
