(function(){

  'use strict';
  /* global angular */

  angular.module("Milestone.Menu")
  
    .controller("CommunitiesMenuNav", ["$scope", "$timeout", "RegionsDataService", function($scope, $timeout, RegionsDataService) {

      $scope.regions = [];
      RegionsDataService.regions
        .then(function(regions){
          $scope.regions = regions;
        });

      $scope.naturalName = function(item) {
        var name = item.name;
        return name.replace(/^The /, '');
      };

    }])

  ;

})();
