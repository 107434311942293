(function() {

  'use strict';
  /* global $, angular */

  angular.module("Milestone").controller("buyingProcessCtrl", ["$scope", "scrollMagic",
    function($scope, scrollMagic) {

      $scope.displaySection = function(inview, inviewpart, event, elem) {
        if (inview && !$(elem).hasClass('visible')) {
          $(elem).height($(elem).height()).addClass('visible');
          $(elem).find('.fader').hide().velocity('fadeIn', {
            stagger: 300,
            complete: function() {
              $(elem).find('.flipX').hide().velocity('transition.flipXIn', {
                delay: 100,
                duration: 600
              });
            }
          });
          // var isVisible = true;
        }
      };

      $scope.displaySectionSlideUp = function(inview, inviewpart, event, elem, transition) {
        if (inview && !$(elem).hasClass('visible')) {
          $(elem).height($(elem).height()).addClass('visible');
          $(elem).find('.fader').hide().velocity('transition.' + transition, {
            stagger: 300
          });
        }
      };

      scrollMagic.parallax('.buying-process', '.background-image', '200%');

    }
  ]);

})();
