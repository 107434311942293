(function() {

  'use strict';
  /* global $, angular */

  angular
    .module("common")

    .directive('slideshow', function() {
      return function(scope, element, attrs) {

        $(document).ready(resizeElem);
        $(window).resize(resizeElem);

        function resizeElem(){    

          var win = $(window).height();
          var resizeHeight = win;

          if(win > 1080){
            resizeHeight = win * 0.70;
          }

          if($('.homepage').length){
            // resizeHeight = win - $('.where-we-build').outerHeight();
            resizeHeight = win - parseInt($('.homepage .cardStack.one').css('marginTop'));
          }

          $(element).height(resizeHeight);

          if($('.cardStack-wrap').length){
            // $('.cardStack-wrap').css('margin-top',resizeHeight);
            $('.cardStack-wrap').css('margin-top',win);
          }

          if($('.affix-spacer').length){
            $('.affix-spacer').css('margin-top',resizeHeight - 75);
          }

          //remove carousel navs if slideshow has only one slide
          if($(element).find('.item').length == 1){
            $(element).find('.carousel-control').hide();
          }

        }

      };
    })
  ;

})();
