(function() {

  'use strict';
  /* global angular */

  angular
    .module("Milestone.microsite")

    .service('GalleryPhotosService', function() {

      return {
        data: defautlGalleryPhotos
      };

    })
  ;

  var defautlGalleryPhotos = [
    {
      caption: 'Image 1',
      category: 'Kitchens',
      srcSmall: '/images/site/microsite/gallery-small-01.png',
      srcBig: '/images/site/microsite/gallery-big-01.jpg'
    },
    {
      caption: 'Image 2',
      category: 'Kitchens',
      srcSmall: '/images/site/microsite/gallery-small-02.png',
      srcBig: '/images/site/microsite/gallery-big-02.jpg'
    },
    {
      caption: 'Image 3',
      category: 'Bedrooms',
      srcSmall: '/images/site/microsite/gallery-small-03.png',
      srcBig: '/images/site/microsite/gallery-big-03.jpg'
    },
    {
      caption: 'Image 4',
      category: 'Kitchens',
      srcSmall: '/images/site/microsite/gallery-small-04.png',
      srcBig: '/images/site/microsite/gallery-big-01.jpg'
    },
    {
      caption: 'Image 5',
      category: 'Bedrooms',
      srcSmall: '/images/site/microsite/gallery-small-05.png',
      srcBig: '/images/site/microsite/gallery-big-02.jpg'
    },
    {
      caption: 'Image 6',
      category: 'Bedrooms',
      srcSmall: '/images/site/microsite/gallery-small-06.png',
      srcBig: '/images/site/microsite/gallery-big-03.jpg'
    },
    {
      caption: 'Image 7',
      category: 'Exteriors',
      srcSmall: '/images/site/microsite/gallery-small-01.png',
      srcBig: '/images/site/microsite/gallery-big-01.jpg'
    },
    {
      caption: 'Image 8',
      category: 'Kitchens',
      srcSmall: '/images/site/microsite/gallery-small-02.png',
      srcBig: '/images/site/microsite/gallery-big-02.jpg'
    },
    {
      caption: 'Image 9',
      category: 'Bathrooms',
      srcSmall: '/images/site/microsite/gallery-small-03.png',
      srcBig: '/images/site/microsite/gallery-big-03.jpg'
    },
    {
      caption: 'Image 10',
      category: 'Bathrooms',
      srcSmall: '/images/site/microsite/gallery-small-04.png',
      srcBig: '/images/site/microsite/gallery-big-01.jpg'
    },
    {
      caption: 'Image 11',
      category: 'Exteriors',
      srcSmall: '/images/site/microsite/gallery-small-05.png',
      srcBig: '/images/site/microsite/gallery-big-02.jpg'
    },
    {
      caption: 'Image 12',
      category: 'Exteriors',
      srcSmall: '/images/site/microsite/gallery-small-06.png',
      srcBig: '/images/site/microsite/gallery-big-03.jpg'
    }
  ];

})();