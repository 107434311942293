(function() {

  'use strict';
  /* global angular */

  angular
    .module('Milestone.microsite', [
      'angular-inview',
      'common',
      'commonServices',
      'ui.bootstrap',
      'ngRoute',
      'cfp.loadingBarInterceptor',
      'Milestone',
      'Milestone.Menu',
      'Milestone.Slideshow',
      'Milestone.DataServices',
      'Milestone.quickStayConnected',
      'Milestone.Maps',
      'Milestone.Forms',
      'Milestone.Scrollspy',
      'Milestone.VelocityJs',
      'Milestone.mySavedHomes',
      'angular-loading-bar'
    ])

    .config(['$interpolateProvider', function($interpolateProvider) {
      $interpolateProvider.startSymbol('<%');
      $interpolateProvider.endSymbol('%>');
    }])
    .config(['$routeProvider', '$locationProvider',
      function($routeProvider, $locationProvider) {
        $locationProvider.html5Mode(true);

        $routeProvider
          .when('/:page?/:floorplan?', {
          })
          .otherwise({
            template: "<div></div>",
            controller: ['$window', '$location', '$rootScope',
              function($window, $location, $rootScope) {
                $window.location.href = $location.absUrl();
              }
            ]
          })
        ;
      }
    ])
    .service('MicrositeScrollAnimationQueue', [function() {
      var data = {
        animation: false
      };

      return data;
    }])
    .run(['$rootScope', '$location', '$anchorScroll', '$routeParams', '$timeout', 'MicrositeScrollAnimationQueue', '$window',
      function($rootScope, $location, $anchorScroll, $routeParams, $timeout, MicrositeScrollAnimationQueue, $window) {

        var isLoaded = false;

        var scrollToUrl = function() {

          var hash = $routeParams.page;

          var needtoScroll = 0;


          if (!hash) {
            console.log("no hash");
            $('html, body').stop(true).animate({
              scrollTop: needtoScroll
            }, 800, 'easeOutQuart');
          }

          var a = $('#' + hash);
          var offset = 67;

          if (a.length) {
            if (a.data !== undefined) {
              if (a.data().offset !== undefined) {
                offset = a.data().offset;
              }
            }

            needtoScroll = a.offset().top - offset;

            if(isLoaded){
              console.log("after loaded");
              $('html, body').stop(true).animate({
                scrollTop: needtoScroll
              }, 800, 'easeOutQuart');
            }else{

              $timeout(function(){
                needtoScroll = a.offset().top - offset;
                console.log("first load offset: " + needtoScroll);
                $('html, body').stop().animate({
                  scrollTop: needtoScroll
                }, 800, 'easeOutQuart');
                isLoaded = true;
              },1500);

            }

          } else {
            console.log("init anchorscroll");
            $anchorScroll(hash);

          }

        };

        // Manuall scrolling stops any animations
        $('html, body').bind('scroll mousedown wheel DOMMouseScroll mousewheel keyup', function(e){
          $("html, body").stop(true);
        });

        $rootScope.$on('$routeChangeStart', function() {
          $rootScope.isRouteLoading = true;
        });

        //when the route is changed scroll to the proper element.
        $rootScope.$on('$routeChangeSuccess', function(newRoute, oldRoute) {
          scrollToUrl();
        });

        $rootScope.scrollToUrl = scrollToUrl;
      }
    ])
  ;

})();
