
angular.module('Milestone')

/* global $, angular, LassoCRM, clicky, clicky_custom, _gaq */
  .controller('BuyingNewVsResaleCtrl', ['$scope', '$http', 'CommunitiesListDataService', 'UserDataService',
    function ($scope, $http, CommunitiesListDataService, UserDataService) {
      'use strict';
      $scope.regions = CommunitiesListDataService.regions;

      $scope.labels = {
        hear: 'how did you hear about us?',
        decisionStage: 'decision stage'
      };
      $scope.hears = [
        { 'name': 'From a Friend' },
        { 'name': 'Social Media' },
        { 'name': 'TV' },
        { 'name': 'Print' },
        { 'name': 'Radio' }
      ];
      $scope.decisionStages = [
        { 'name': 'Researching' },
        { 'name': 'Comparing Options' },
        { 'name': 'Ready to Buy' }
      ];

      $scope.signup = {};
      UserDataService.getUser().then(function (user) {
        $scope.signup = angular.copy(user);
        $scope.signup.origin = window.location.href;
        $scope.signup.type = 'buyingNewVsResale';
        $scope.signup.communities = [];
        $scope.signup.hears = [];
        $scope.signup.form_id = 177543;
        $scope.signup.guid = window.getLassoGuid();
      });

      $scope.hideForm = false;
      $scope.showMessage = false;
      $scope.showLoader = false;
      $scope.noCommunities = false;
      $scope.triedSubmit = false;

      $scope.noCommunities = function () {
        return ($scope.signup.communities.length === 0);
      };

      $scope.checkFormValidation = function (form) {
        $scope.triedSubmit = true;

        if (!form.$valid) {
          return;
        }

        if ($scope.noCommunities()) {
          return;
        }
        processBuyingNewVsResaleForm();
      };

      function processBuyingNewVsResaleForm () {
        $scope.hideForm = true;
        $scope.showLoader = true;
        $http({
          method: 'POST',
          url: '/form/to-database',
          data: $.param($scope.signup), // pass in data as strings
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' } // set the headers so angular passing info as form data (not request payload)
        })
          .success(function (data) {
            $scope.showLoader = false;
            if (data.status === 'success') {
              // Tie lead id and email to clicky analytics data
              clicky_custom.visitor = {
                lead_id: data.id,
                email: data.email,
                signup: '1'
              };

              clicky_custom.goal = { name: 'Buying New vs. Resale Signup' };

              // Log action in clicky
              clicky.goal('2674'); // Logs a "buying-new-vs-resale" goal hit in clicky
              clicky.log('/buying-new-vs-resale/thank-you'); // Logs a fake page hit in clicky

              // Fire off a Facebook Pixel Code (Lead)
              fbq('track', 'Lead');

              // Fire off a Google Analytics goal hit
              _gaq.push(['_trackPageview', '/ajax-goals/buying-new-vs-resale']);

              $scope.message = data.message;
              $scope.showMessage = true;
            } else {
              $scope.message = data.message;
              $scope.showMessage = true;
            }
          });
      }
    }
  ]);
