(function() {

  'use strict';
  /* global angular */

  angular.module("Milestone").directive('customScrollbar', function() {
    return function(scope, element, attrs) {};
  });

})();
