/*jslint white: true, nomen: true */
/*global angular*/

(function() {

  'use strict';
  /* global angular */

  angular
    .module("Milestone.Forms")

    .controller('AccordionCtrl', ['$scope', function($scope) {
      // Sets up behavior of the accordion
      // groupOPen: Place true at the desired index to choose a default open group
      // oneAtATime: Select to force a single group to display at a time
      $scope.status = {
        groupOpen: [true],
        oneAtATime: true
      };
    }]);

}());
