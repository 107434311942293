(function () {
  'use strict';
  /* global $, angular, clicky, clicky_custom, _gaq */

  angular.module('Milestone').controller('popupCovid19Ctrl', ['$scope', '$http', '$window', 'localStorageService',
    function ($scope, $http, $window, localStorageService) {
      $scope.localStorageKey = 'popupCovid19';

      if (localStorageService.get($scope.localStorageKey)) {
        $('.popup-message').removeClass('is-open');
      } else {
        $('.popup-message').addClass('is-open');
      }

      $scope.closePopupCovid19 = function () {
        localStorageService.set($scope.localStorageKey, true);
        $('.popup-message').removeClass('is-open');
      };
    }
  ]);
})();
