(function() {

  'use strict';
  /* global $, angular */

  angular.module("Milestone.VelocityJs")
  .directive('velocityJs', function() {
    return function(scope, elem, attrs) {
      elem = $(elem);
      var attr = elem.data('absolute');
      var attrStagger = elem.data('stagger');
      var pageOnLoad = elem.data('pageload');
      if (typeof attr !== typeof undefined && attr !== false) {
        elem.parent().height(elem.parent().height());
      }
      if (typeof attrStagger !== typeof undefined && attrStagger !== false) {
        if (scope.$last) {
          elem.parent().find('.fader').hide().velocity("transition." + elem.data('transition'), {
            delay: elem.data('delay'),
            stagger: elem.data('stagger')
          });
        }
      } else if (typeof pageOnLoad !== typeof undefined && pageOnLoad !== false) {
        elem.velocity("transition." + elem.data('transition'), {
          delay: elem.data('delay'),
          duration: elem.data('duration')
        });
      } else {
        elem.hide().velocity("transition." + elem.data('transition'), {
          delay: elem.data('delay'),
          duration: elem.data('duration')
        });
      }
    };
  });

})();
