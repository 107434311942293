(function() {

    'use strict';
    /* global $, angular */

    angular.module('Milestone')
        .directive('msComingSoonCommunityDropdown', function() {
            return {
                restrict: 'E',
                scope: {
                    text: '@',
                    checkboxPrefix: '@',
                    initialSelection: '=initial',
                    selectedCommunities: '=ngModel',
                    communities: '='
                },
                templateUrl: 'ms-coming-soon-community-dropdown.html',
                link: function($scope) {

                },
                controller: ["$scope", function($scope) {

                    $scope.labels = {
                        community: $scope.text
                    };
                    $scope.selectedCommunities = [];

                    // toggle community selections
                    $scope.toggleCommunitySelection = function toggleCommunitySelection(id) {
                        var idx = $scope.selectedCommunities.indexOf(id);
                        // is currently selected
                        if (idx > -1) {
                            $scope.selectedCommunities.splice(idx, 1);
                        }
                        // is newly selected
                        else {
                            $scope.selectedCommunities.push(id);
                        }

                        // Update the dropdown label
                        updateLabel(false);
                    };

                    var updateLabel = function updateLabel(name) {
                        var size = $scope.selectedCommunities.length;

                        // single-select mode
                        if (name) {
                            $scope.labels.community = size == 0 ? $scope.text : name + " selected";
                        } else {
                            // multi-select mode
                            switch (size) {
                                case 0:
                                    $scope.labels.community = $scope.text;
                                    break;
                                case 1:
                                    $scope.labels.community = size + ' community selected';
                                    break;
                                default:
                                    $scope.labels.community = size + ' communities selected';
                                    break;
                            }
                        }
                    };

                    $scope.selectedCommunities.push($scope.initialSelection);
                    updateLabel(false);

                }]
            };
        });

})();