(function() {
  'use strict';

  angular
    .module("Milestone.microsite")
    .directive("houseCard", ['$timeout',
      function($timeout) {
        return {
          link: function(scope, element, attrs) {
            element.addClass('house-card');

            if(scope.$last){
              $timeout(function() {

                var h = 0;

                $('.floorplans-container .house-card').each(function(){
                  if($(this).outerHeight() > 0){
                    // 2021/10/07 - denny: reduce white space
                    // h = $(this).outerHeight() + parseInt($(this).css('padding-top'));
                    h = $(this).outerHeight();
                  }
                });

                $('.floorplans-container .house-card').css('min-height',h);

              }, 0);
            };

            var defaultLink = element[0].querySelector('.default-link');

            element.click(function clickDefaultBubble(event) {
              defaultLink.click();
            });

          }
        };
      }
    ])
  ;

})();