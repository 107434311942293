(function () {
  /* global LassoCRM, clicky, clicky_custom, _gaq */
  'use strict';

  angular
    .module('Milestone.Menu')

    .controller('StayInTouchFormCtrl', ['$scope', '$http', '$window', 'RegionsDataService', 'UserDataService',
      function ($scope, $http, $window, RegionsDataService, UserDataService) {
        $scope.reasons = [
          { 'name': 'Reason 1' },
          { 'name': 'Reason 2' },
          { 'name': 'Reason 3' },
          { 'name': 'Reason 4' },
          { 'name': 'Reason 5' },
          { 'name': 'Reason 6' }
        ];

        $scope.regions = [];
        RegionsDataService.regions
          .then(function(regions){
            $scope.regions = _.filter(regions, function (region) {
              return region.communities.length > 0;
            });
          });

        $scope.signup = {};
        UserDataService.getUser().then(function (user) {
          $scope.signup = angular.copy(user);
          $scope.signup.opt_in = 1;
          $scope.signup.origin = window.location.href;
          $scope.signup.type = 'stayInTouch';
          $scope.signup.communities = [];
          $scope.signup.form_id = 177545;
          $scope.signup.guid = window.getLassoGuid();
        });

        $scope.hideForm = false;
        $scope.showMessage = false;
        $scope.showLoader = false;
        $scope.noCommunities = false;
        $scope.triedSubmit = false;

        $scope.noCommunities = function () {
          return ($scope.signup.communities.length === 0);
        };

        $scope.checkFormValidation = function (form) {
          $scope.triedSubmit = true;

          if (!form.$valid) {
            return;
          }

          if ($scope.noCommunities()) {
            return;
          }
          processStayInTouchForm();
        };

        function processStayInTouchForm () {
        	$scope.hideForm = true;
        	$scope.showLoader = true;
        	$http({
        		method: 'POST',
        		url: '/form/to-database',
        		data: $.param($scope.signup), // pass in data as strings
        		headers: { 'Content-Type': 'application/x-www-form-urlencoded' } // set the headers so angular passing info as form data (not request payload)
        	})
        	.success(function (data) {
        		$scope.showLoader = false;
        		if (data.status === 'success') {
        		  // Tie lead id and email to clicky analytics data
        		  clicky_custom.visitor = {
        		    lead_id: data.id,
                email: data.email,
        		    signup: '1'
                };

                clicky_custom.goal = {
                  name: 'Quick Signup'
                };

                // Log action in clicky
                clicky.goal('2672'); // Logs a "quick-signup" goal hit in clicky
                clicky.log('/quick-signup/thank-you'); // Logs a fake page hit in clicky

                // Fire off a Facebook Pixel Code (Lead)
                fbq('track', 'Lead');

                // Fire off a Google Analytics goal hit
                _gaq.push(['_trackPageview', '/ajax-goals/quick-signup-main-nav']);

                if (typeof googleConversionId !== 'undefined' && typeof googleConversionLabel !== 'undefined' && googleConversionId && googleConversionLabel) {
                  $window.google_trackConversion({
                    google_conversion_id: googleConversionId,
                    google_conversion_language: 'en',
                    google_conversion_format: '3',
                    google_conversion_color: 'ffffff',
                    google_conversion_label: googleConversionLabel,
                    google_remarketing_only: false
                  });
                }

        			$scope.message = data.message;
        			$scope.showMessage = true;
        		} else {
        			$scope.message = data.message;
        			$scope.showMessage = true;
        		}
        	});
        }
      }
    ])

  ;
})();
