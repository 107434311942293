;(function() {

  'use strict';
  /* global angular */

  angular
    .module('Milestone')

    .controller('TestimonialsCtrl', ['$scope', '$modal', 'TestimonialsListDataService',
      function($scope, $modal, TestimonialsListDataService) {
        $scope.testimonials = TestimonialsListDataService.testimonials;
        
        $scope.openTestimonial = function (testimonial_id) {
          var properties = {};
          
          if (testimonial_id) properties.id = testimonial_id;
          
          $scope.testimonial = _.find($scope.testimonials, function (testimonial) {
              if (_.matches(properties)(testimonial)) {
                return testimonial;
              }
          });

          var modalInstance = $modal.open({
            animation: true,
            templateUrl: 'testimonial.html',
            controller: 'testimonialModalCtrl',
            windowClass: 'modal-testimonial',
            size: 'lg',
            scope: $scope
          });
        };
      }
    ])
    
    .controller('testimonialModalCtrl', ['$scope', '$modalInstance',
      function($scope, $modalInstance) {
        
        $scope.close = function(){
          $modalInstance.close();
        }
      }
    ])
    
    .filter('trustAsHtml', ['$sce', function($sce) {
      return function(val) {
        return $sce.trustAsHtml(val);
      };
    }])

  ;

})();