angular.module('Milestone').controller('WarrantyRequestCtrl', ['$scope', '$http', 'CommunitiesListDataService', 'UserDataService', 'Upload',
  function ($scope, $http, CommunitiesListDataService, UserDataService, Upload) {
    'use strict';
    /* global angular, $ */

    $scope.regions = CommunitiesListDataService.regions;
    $scope.requests = 1;

    $scope.getRequests = function (num) {
      return new Array(num);
    };

    $scope.addAnotherRequest = function () {
      $scope.requests++;
      return false;
    };

    /* please ping me (Denny), the reason I comment it out because for mobile view the mobile footer nav is not visible
        $scope.blockScrollX = function() {
          $('body').css('overflow-x', 'hidden');
        };
        */

    $scope.displayInteriorImg = function (inview, inviewpart, event) {
      if (inviewpart) {
        $('.interior-img').addClass('fade-in');
      }
    };

    // please ping me (Denny), the reason I comment it out because for mobile view the mobile footer nav is not visible
    // $scope.blockScrollX();

    $scope.signup = {};
    UserDataService.getUser().then(function (user) {
      $scope.signup = angular.copy(user);
      $scope.signup.origin = window.location.href;
      $scope.signup.type = 'warrantyServiceRequest';
      $scope.signup.guid = window.getLassoGuid();
    });

    $scope.hideForm = false;
    $scope.showMessage = false;
    $scope.showLoader = false;
    $scope.triedSubmit = false;

    $scope.showimageUploadErrorMessage = [];
    $scope.showImageUploadSuccessMessage = [];
    $scope.showPdfUploadErrorMessage = false;
    $scope.showPdfUploadSuccessMessage = false;
    $scope.imageUploadProgress = [];
    var maxFileSize = 10240000;

    $scope.emailsMatch = function () {
      return $scope.signup.email === $scope.signup.confirm_email;
    };

    $scope.uploadImageFiles = function (files, index) {
      var data;

      if (files && files.length) {
        data = {
          // name: file.name,
          destinationPath: '/warranties/images',
          files: files
        };

        var uploadPromise = Upload.upload({
          url: '/owning-milestone/warranty-service-requests-upload-file',
          data: data
        }).then(function (resp) {
          $scope.signup.image_files[index] = resp.data.uploadedFiles;
          $scope.showimageUploadErrorMessage[index] = false;
          $scope.showImageUploadSuccessMessage[index] = true;
        }, function (resp) {
          $scope.showImageUploadSuccessMessage[index] = false;
          $scope.showimageUploadErrorMessage[index] = true;
        }, function (evt) {
          $scope.imageUploadProgress[index] = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
        });

        return uploadPromise;
      } else {
        $scope.showImageUploadSuccessMessage[index] = false;
        $scope.showimageUploadErrorMessage[index] = true;
      }
    };

    $scope.uploadPdfFiles = function (files) {
      var data;

      if (files && files.length) {
        data = {
          // name: file.name,
          destinationPath: '/warranties/pdfs',
          files: files
        };

        var uploadPromise = Upload.upload({
          url: '/owning-milestone/warranty-service-requests-upload-file',
          data: data
        }).then(function (resp) {
          $scope.signup.pdf_files = resp.data.uploadedFiles;
          $scope.showPdfUploadErrorMessage = false;
          $scope.showPdfUploadSuccessMessage = true;
        }, function (resp) {
          $scope.showPdfUploadSuccessMessage = false;
          $scope.showPdfUploadErrorMessage = true;
        }, function (evt) {
          $scope.pdfUploadProgress = Math.min(100, parseInt(100.0 * evt.loaded / evt.total));
        });

        return uploadPromise;
      } else {
        $scope.showPdfUploadSuccessMessage = false;
        $scope.showPdfUploadErrorMessage = true;
      }
    };

    $scope.checkFormValidation = function (form) {
      $scope.triedSubmit = true;

      if (!form.$valid) {
        return;
      }

      if (!$scope.emailsMatch()) {
        return;
      }

      processWarrantyServiceRequestForm();
    };

    function processWarrantyServiceRequestForm () {
      $scope.hideForm = true;
      $scope.showLoader = true;
      $http({
        method: 'POST',
        url: '/form/to-log-file',
        params: $scope.signup,
        paramSerializer: '$httpParamSerializerJQLike',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        } // set the headers so angular passing info as form data (not request payload)
      })
        .success(function (data) {
          $scope.showLoader = false;
          if (data.status === 'success') {
            // Fire off a Facebook Pixel Code (CompleteRegistration)
            fbq('track', 'CompleteRegistration');

            $scope.message = data.message;
            $scope.showMessage = true;
          } else {
            $scope.message = data.message;
            $scope.showMessage = true;
          }
        });
    }
  }
])
;
