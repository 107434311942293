angular
  .module('Milestone.microsite')

  .directive('msPhotoGallery', function () {
    'use strict';
    return {
      link: function (scope, element, attributes) {}
    };
  })

  .animation('.velocity-cardFlip', ['$timeout',
    function ($timeout) {
      'use strict';
      var time;
      return {

        enter: function (element, done) {
          $(element).css('display', 'inline-block').removeClass('shown');
          $timeout(function () {
            $(element).addClass('flip-x-in');
          }, time += 150);
        },

        leave: function (element) {
          time = 0;
          $(element).removeClass('flip-x-in').hide();
        }
      };
    }
  ])

  .controller('MicrositeCtrl', ['$scope', '$rootScope', 'device', '$http', '$window', '$location', '$sce', '$filter', 'CommunityService', 'LocalAmenitiesService', 'CommunitySitePlanService', 'GalleryPhotosService', 'HomeFeaturesService', 'UserDataService', 'msSlideShowDataService', '$routeParams', '$interval', '$timeout',
    function ($scope, $rootScope, device, $http, $window, $location, $sce, $filter, CommunityService, LocalAmenitiesService, CommunitySitePlanService, GalleryPhotosService, HomeFeaturesService, UserDataService, msSlideShowDataService, $routeParams, $interval, $timeout) {
      'use strict';
      /* global $, angular, LassoCRM, clicky, clicky_custom, _gaq */

      $scope.selectedAmenity = 0;
      $scope.currentSection = '';

      $scope.community = CommunityService.data;
      $scope.communitySitePlan = CommunitySitePlanService.data;

      $scope.hasFloorplans = $scope.community.residence_series.length || $scope.community.residences.length;
      if (($filter('filter')($scope.community.residence_series, { is_active: 1 })).length) {
        $scope.residenceSeriesId = ($filter('orderBy')($filter('filter')($scope.community.residence_series, { is_active: 1 }), 'sequence_position'))[0].id;
      }

      // The child controller will tell us if we should show the Gallery.
      // If the child doesn't tell us to, we assume there is no Gallery
      // for this Community.
      $scope.showTheGallery = false;
      $scope.$on('galleryPhotosCtrl:showTheGallery', function (event) {
        $scope.showTheGallery = true;

        // This is the only place we want to catch this, so we can stop it.
        event.stopPropagation();
      });

      $scope.routeParams = $routeParams;

      $scope.$on('$locationChangeSuccess', function (event, now, was) {
        $timeout(function () {
          $scope.activeTab = $routeParams.page;
        });
      });

      $scope.closeSubnav = function (elem) {
        $(elem).trigger('click');
      };

      $scope.displayWeb = function (inview, inviewpart, event) {
        if (inviewpart) {
          $('.why-choose').addClass('fade-in');
        }
      };

      $scope.displayCommunity = function (inview, inviewpart, event) {
        if (inview) {
          $('.new-communities').addClass('fade-in');
          $('.new-communities').addClass('slide-up');
          var imgHeight = $('.why-choose-right').height();
          $('img.left-img-half').css('height', imgHeight);
        }
      };

      $scope.displayLeftImage = function (inview, inviewpart, event) {
        if (inviewpart) {
          $('.left-half').addClass('slide-in-left');
        }
      };

      $scope.displayAccolades = function (inview, inviewpart, event) {
        if (inviewpart) {
          $('.accollades').addClass('fade-in');
        }
      };

      $scope.activeForm = false;

      $scope.hasFeatures = false;

      HomeFeaturesService.data
        .then(function(data) {
          $scope.hasFeatures = data.features.length > 0;
        });

      $scope.persistentFeatures = {
        show: function (event, type) {
          $scope.activeForm = type;

          if (type === 'schedule') {
            $scope.persistantHide('.schedule-a-tour-content', '.receive-updates-content');
            $('.persistent-features-content').addClass('schedule-a-tour-active').removeClass('receive-updates-active');
          } else {
            $scope.persistantHide('.receive-updates-content', '.schedule-a-tour-content');
            $('.persistent-features-content').addClass('receive-updates-active').removeClass('schedule-a-tour-active');
          }

          $('.receive-updates').css('top', 'auto');
          $('.receive-updates').css('bottom', event.clientY);
        },
        hide: function (force) {
          if (!force && $scope.activeForm === 'schedule' && $scope.scheduleForm.$dirty) {
            return;
          }

          if (!force && $scope.activeForm === 'updates' && $scope.updatesForm.$dirty) {
            return;
          }

          $('.schedule-a-tour-content, .receive-updates-content').velocity('stop').velocity('transition.flipYOut', 300, 'easeOutQuad');
          $('.schedule-a-tour, .receive-updates').removeClass('persistent-feature-active');
          $('.persistent-features-content').removeClass('schedule-a-tour-active').removeClass('receive-updates-active');
          $scope.activeForm = false;
        }
      };

      $scope.mobileSlideInOut = function (event, type, elem) {
        if (device.check() === 'phone') {
          // is close button?
          if (elem !== 'close') {
            $(elem).show().siblings().hide();
          }

          // scroll form to top;
          $('.persistent-features-content').animate({
            scrollTop: 0
          }, 0);

          // toggle form
          $('.persistent-features').toggleClass('open');
        } else {
          $scope.persistentFeatures.show(event, type);
        }
      };

      $scope.persistantHide = function (elemOne, elemTwo) {
        if (!$(elemOne).is(':visible')) {
          $(elemTwo).velocity('stop').velocity('transition.flipYOut', 300, 'easeOutQuad', function () {
            $(elemOne).velocity('stop');
            // animate based on final button selected
            var elem = '.' + $('.persistent-feature-active').attr('class').replace('persistent-feature-active', '').trim() + '-content';
            $(elem).velocity('transition.flipYIn', 300, 'easeInQuad');
          });
        }

        var buttonOne = elemOne.replace('-content', '');
        var buttonTwo = elemTwo.replace('-content', '');
        $(buttonOne).addClass('persistent-feature-active');
        $(buttonTwo).removeClass('persistent-feature-active');
      };

      // In View
      $scope.displayView = function (inview, inviewpart, event, target) {
        var $elem = $(target);
        if (inview && !$elem.hasClass('is_seen')) {
          console.log('in view');
          $elem.addClass('is_seen');
          $scope.displayTransition($elem);
        }
      };

      $scope.displayGallery = function (inview, inviewpart, event) {
        var $elem = $('#gallery > .fader');
        if (inview && !$elem.hasClass('is_seen')) {
          $elem.addClass('is_seen');
          $elem.hide().velocity('fadeIn', {
            stagger: 200
          });
        }
      };

      $scope.displayTransition = function ($elem) {
        // $elem.addClass('is_seen').height($elem.height());
        $elem.hide().velocity('fadeIn', {
          stagger: 200
        });
      };

      $scope.signup = {};
      UserDataService.getUser().then(function (user) {
        $scope.signup = angular.copy(user);
        $scope.signup.opt_in = 1;
        $scope.signup.origin = window.location.href;
        $scope.signup.type = 'signUpMicrosite';
        $scope.signup.communities = [];
        $scope.signup.communities[0] = $scope.community.id;
        $scope.signup.form_id = 177545;
        $scope.signup.guid = window.getLassoGuid();
      });

      $scope.hideForm = false;
      $scope.showMessage = false;
      $scope.showLoader = false;
      $scope.triedSignupSubmit = false;

      $scope.checkSignupFormValidation = function (form) {
        $scope.triedSignupSubmit = true;

        if (!form.$valid) {
          return;
        }

        processQuickSignUpForm();
      };

      var processQuickSignUpForm = function () {
        $scope.hideForm = true;
        $scope.showLoader = true;
        $http({
          method: 'POST',
          url: '/form/to-database',
          data: $.param($scope.signup), // pass in data as strings
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          } // set the headers so angular passing info as form data (not request payload)
        }).success(function (data) {
          $scope.showLoader = false;
          if (data.status === 'success') {
            // Tie lead id and email to clicky analytics data
            clicky_custom.visitor = {
              lead_id: data.id,
              email: data.email,
              signup: '1'
            };

            clicky_custom.goal = {
              name: 'Quick Signup'
            };

            // Log action in clicky
            clicky.goal('2672'); // Logs a "quick-signup" goal hit in clicky
            clicky.log('/quick-signup/thank-you'); // Logs a fake page hit in clicky

            // Fire off a Facebook Pixel Code (Lead)
            fbq('track', 'Lead');

            // Fire off a Google Analytics goal hit
            _gaq.push(['_trackPageview', '/ajax-goals/quick-signup-microsite']);

            if (typeof googleConversionId !== 'undefined' && typeof googleConversionLabel !== 'undefined' && googleConversionId && googleConversionLabel) {
              $window.google_trackConversion({
                google_conversion_id: googleConversionId,
                google_conversion_language: 'en',
                google_conversion_format: '3',
                google_conversion_color: 'ffffff',
                google_conversion_label: googleConversionLabel,
                google_remarketing_only: false
              });
            }

            $scope.message = data.message;
            $scope.showMessage = true;
          } else {
            $scope.message = data.message;
            $scope.showMessage = true;
            $scope.hideForm = false;
          }
        });
      };

      $scope.labels = {
        timeOfTour: 'preferred time of tour'
      };

      $scope.tourTimes = [
        {
          'name': 'in the morning'
        },
        {
          'name': 'in the evening'
        }
      ];

      $scope.schedule = {};
      UserDataService.getUser().then(function (user) {
        $scope.schedule = angular.copy(user);
        $scope.schedule.opt_in = 1;
        $scope.schedule.origin = window.location.href;
        $scope.schedule.type = 'scheduleATour';
        $scope.schedule.communities = [];
        $scope.schedule.communities[0] = $scope.community.id;
        $scope.schedule.form_id = 177544;
        $scope.schedule.guid = window.getLassoGuid();
      });

      $scope.hideScheduleForm = false;
      $scope.showScheduleMessage = false;
      $scope.showScheduleLoader = false;
      $scope.noDate = false;
      $scope.triedScheduleSubmit = false;

      $scope.noDate = function () {
        return $scope.schedule.date_of_tour === null;
      };

      $scope.checkScheduleFormValidation = function (form) {
        $scope.triedScheduleSubmit = true;

        if (!form.$valid) {
          return;
        }

        processScheduleATourForm();
      };

      var processScheduleATourForm = function () {
        $scope.hideScheduleForm = true;
        $scope.showScheduleLoader = true;
        $http({
          method: 'POST',
          url: '/form/to-database',
          data: $.param($scope.schedule), // pass in data as strings
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          } // set the headers so angular passing info as form data (not request payload)
        }).success(function (data) {
          $scope.showScheduleLoader = false;
          if (data.status === 'success') {
            // Tie lead id and email to clicky analytics data
            clicky_custom.visitor = {
              lead_id: data.id,
              email: data.email,
              signup: '1'
            };

            clicky_custom.goal = {
              name: 'Schedule A Tour'
            };

            // Log action in clicky
            clicky.goal('2675'); // Logs a "schedule-a-tour" goal hit in clicky
            clicky.log('/schedule-a-tour/thank-you'); // Logs a fake page hit in clicky

            // Fire off a Facebook Pixel Code (Lead)
            fbq('track', 'Lead');

            // Fire off a Google Analytics goal hit
            _gaq.push(['_trackPageview', '/ajax-goals/schedule-a-tour']);

            if (typeof googleConversionId !== 'undefined' && typeof googleConversionLabel !== 'undefined' && googleConversionId && googleConversionLabel) {
              $window.google_trackConversion({
                google_conversion_id: googleConversionId,
                google_conversion_language: 'en',
                google_conversion_format: '3',
                google_conversion_color: 'ffffff',
                google_conversion_label: googleConversionLabel,
                google_remarketing_only: false
              });
            }

            $scope.scheduleMessage = data.message;
            $scope.showScheduleMessage = true;
          } else {
            $scope.scheduleMessage = data.message;
            $scope.showScheduleMessage = true;
          }
        });
      };
    }
  ])
  .controller('favoriteNotLoggedInModalCtrl', ['$scope', '$modalInstance', function ($scope, $modalInstance) {
    'use strict';
    $scope.close = function () {
      $modalInstance.close();
    };
  }])
;
