;(function() {

  'use strict';
  /* global $, angular */

  angular
    .module("Milestone.microsite")

    .controller("CarouselCtrl", ['$scope', 'CommunityService',
      function($scope, CommunityService) {

        $scope.community = CommunityService.data;

        $scope.swipeLeft = function(){
          console.log("swiped next");
          $('.carousel').carousel('next');
        }

        $scope.swipeRight = function(){
          console.log("swiped prev");
          $('.carousel').carousel('prev');
        }

      }
    ])
  ;

})();