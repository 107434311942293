(function(){
  
  'use strict';
  /* global angular */

  angular
    .module('Milestone.HomePage', ['commonServices'])

  ;

})();
