(function() {

  'use strict';
  /* global angular */

  angular.module("Milestone").directive('mobileAutoWidth', function() {
    return function(scope, elem, attrs) {

    	$(window).load(resizeWidth);
    	$(window).resize(resizeWidth);

    	function resizeWidth(){
			 $(elem).width($(window).width() - (attrs.offset * 2));
    	}

    };
  });

})();
