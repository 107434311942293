(function() {

  'use strict';
  /* global $, angular */

  angular
    .module("Milestone")

    .directive('splitAt', function() {
      return {
        link: function(scope, elem, attrs) {

          elem = $(elem);

          var content = elem.html();

          var split_phrases = ["at", "on", "of"];

          for (var i = split_phrases.length - 1; i >= 0; i--) {
            var p = split_phrases[i];

            // split on phrase surrounded by spaces
            var split_content = content.split(' ' + p + ' ');

            // only works if we get ["CHUNK", "CHUNK"]
            if (split_content.length === 2) {
              elem.html('<big>' + split_content[0] + '</big> ' +
                '<span class="at">' + p + '</span>' +
                '<small>' + split_content[1] + '</small>');
              return; // don't try to split more phrases, we're done
            }
          }

        }
      };
    })
  ;

})();