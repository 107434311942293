(function() {

  'use strict';
  /* global $, angular */

  angular.module("Milestone").directive('mobileVerticalCenter', ['device','$timeout',function(device,$timeout) {
    return function(scope, elem) {

    	$(window).on('load resize',function(){
    		if(device.check() == 'phone'){

    			$timeout(function(){
    				var outerHeight = $(elem).parent().height();
	    			$(elem).css({
	    				top: outerHeight * 0.5 - $('.footer-mobile').height(),
	    				'margin-top': - ($(elem).height() * 0.5)
	    			});
    			},0)

    		}
      });

    };
  }]);

})();