(function() {

  'use strict';
  /* global $, angular */

  angular
    .module("common")

    .filter('homePrice', ['$filter', function($filter) {
      return function(input, prefix, suffix) {
        if (input.price_status === 'Call For Pricing' || input.price_status === 'Future Release Coming' || input.price_status === 'Pricing To Be Determined' || input.price_status === 'Temporarily Sold Out' || input.price_status === 'Sold Out') {
          return input.price_status;
        }

        var prefix = prefix || '';
        var suffix = suffix || '';

        var price = input.price;

        if (1 * price === 0) {
          return "Price TBD";
        }

        return prefix + "Starting at " + $filter('currency')(price, '$', 0) + suffix;
      };
    }])

    .directive('homePrice', ['$filter', function($filter) {
      return {
        link: function(scope, elem, attrs) {

          elem = $(elem);

          // strip everything that is not a number or .
          var content = elem.html().replace(/[\$\,\sa-zA-Z]/g, '');
          console.log(content);

          var new_content = $filter('homePrice')(content);
          elem.html(new_content);
          return;
        }
      };
    }])
  ;

})();