(function() {

  'use strict';
  /* global $, angular */
  /*This just crossfades between divs using an active class, used with ng-repeat*/

  angular
    .module("Milestone")

    .directive('simpleFade', ["$interval", function($interval) {
      return function(scope, elem, attrs) {

        if(scope.$last){

          var parent = elem.parent();     
          var $slide = $(parent).children("div");

          $(parent).addClass("simple-fade");
          
          $interval(function(){

            var $target = $(parent).children(".active");
            $(parent).children('.active').removeClass('active');

            if(!$target.next().length){
              $slide.first().addClass('active');
            }else{
              $target.next().addClass('active');
            }

          }, attrs.speed);
        }


      };
    }])
  ;

})();
