(function() {

  'use strict';
  /* global $, angular */

  angular.module("Milestone").directive('togglePopup', function() {
    return function(scope, elem) {

      $(elem).bind("click", function() {
        var content = $(elem).find(".description").attr("content");
        var author = $(elem).find(".testimonial-by").text();

        $(".testimonial-popup").addClass('open');
        $(".testimonial-popup .description").text(content);
        $(".testimonial-popup .testimonial-by").text(author);
      });

      $('body').on("click", function(e) {
        //if testimonial is visible
        if(!$(e.target).closest('.grid-item').length && $(".testimonial-popup.open").length){    
          //was outside of testimonial or close button clicked?
          if(!$(e.target).closest(".testimonial-popup.open").length || $(e.target).hasClass("close-btn")){
            $(".testimonial-popup").removeClass('open')
          }
        }

      });

    };
  });

})();