(function() {

  'use strict';
  /* global $, angular */

  angular.module("Milestone").directive('matchElemHeight', [ '$rootScope', '$window', function($rootScope, $window) {
    return function(scope, elem) {

      var w = $($window);
      
      w.bind('load resize', function() {
        var win = w[0].innerHeight;
        var offset = $('.where-we-build').outerHeight() + $('.navbar-fixed-top').outerHeight();
        
        if(win < 800){
          $(elem).height(495);
        }else{
          $(elem).height(win - offset);
        }

        $rootScope.$broadcast('matchElemHeight');
      });

    };
  }]);

})();