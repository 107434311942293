(function() {

  'use strict';
  /* global $, angular */

  angular.module("Milestone.Menu")
  .controller("MenuCtrl", ["$scope", "device", "CommunitiesListDataService", "$rootScope",
    function($scope, device, CommunitiesListDataService, $rootScope) {
      $scope.communities = CommunitiesListDataService.communities;

      $scope.openMicrositeContact = function(community) {
        window.open('/' + community.region_seo_name + '/' + community.seo_name + '/contact-info', '_self');
      };

      $scope.hoverOver = function(event) {
        if(device.touch()){
          $(event.target).closest('.dropdown').addClass('open');
        }
        $(".carousel").carousel("pause");
      };

      $scope.hoverOff = function(event) {
        if(device.touch()){
          $(event.target).closest('.dropdown').removeClass('open');
        }
        $(".carousel").carousel("cycle");
      };

      $scope.toggleHide = function(event) {
        if (!device.touch()) {
          var link = $(event.target).attr("href");
          location.replace(link);
        } else {
          $rootScope.$broadcast('close-register-for-updates');
        }

      };

      $(window).bind('load resize',function(){
        if(device.touch()){
          $('#navbar-collapse .nav.navbar-nav > .dropdown').removeClass('desktop');
        }else{
          $('#navbar-collapse .nav.navbar-nav > .dropdown').addClass('desktop');
        }
      })

    }
  ])

  ;

})();