/* global $, angular */

angular

  .module("common")

   // This mouseover stuff needs to know about the form to 
   // show/hide appropriately when the form is being worked
   // on. It's not easy to re-scope, so by the time it gets
   // here sometimes it will work and sometimes it will get
   // undefined. So check for !== undefined before looking
   // into the form$dirty or whatever.

  .directive('register', function() {
    'use strict';

    return {
      scope: {
        form: '='
      },
      link: function(scope, element, attrs) {

        $('li.register a.dropdown').on('mouseenter touchstart', function(event) {
          event.preventDefault();
          showDropdown($(this));
        });

        $('li.register').on('mouseleave', function() {
          if (scope.form !== undefined) {
            if (!scope.form.$dirty) {
              hideDropdown();
            }
          }
        });

        $('a#close-dropdown').on('click', function(event) {
          event.preventDefault();
          hideDropdown();
        });

        scope.$on('close-register-for-updates', function(event){
          hideDropdown();
        })

        var hideDropdown = function(elem) {
          scope.form.$setPristine();
          return $("li.register").removeClass('open').find('.dropdown + .dropdown-menu').removeClass('is-open');
        };

        var showDropdown = function(elem) {
          if($('.dropdown-menu.main').is(':visible')){
            $('.dropdown-menu.main')
          }
          if (elem.next('.dropdown-menu').css('opacity') == 0) {
            elem.parent()
              .addClass('open').find('.dropdown + .dropdown-menu').addClass('is-open');
          }
        };

        // Prevent dropdown to be closed when we click on an accordion link
        $('.dropdown-accordion').on('click', 'a.accordion-toggle', function(event) {
          event.preventDefault();
          event.stopPropagation();
          $($(this).data('parent')).find('.panel-collapse.in').collapse('hide');
          $($(this).attr('href')).collapse('show');
        });

        // Prevent dropdown from closing when click on checkbox
        $('.dropdown-accordion').on('click', 'ul.checkboxes', function(event) {
          //event.preventDefault();
          event.stopPropagation();
          $($(this).data('parent')).find('.panel-collapse.in').collapse('hide');
          $($(this).attr('href')).collapse('show');
          // console.log("clicked");
        });

      }
    };
  });
