(function() {

  'use strict';
  /* global angular */

  angular
    .module("Milestone.microsite")

    .service('CommunitySitePlanService', ['MicrositeDataCache', function(MicrositeDataCache) {

      var data = {
        lots: MicrositeDataCache.lots
      };

      return {
        data: data
      };

    }])
  ;

})();
