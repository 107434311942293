(function() {

  'use strict';
  /* global $, angular */

  angular
    .module("Milestone")

    .controller("msignatureCtrl", ["$scope", 'CommunitiesListDataService', '$modal', '$timeout',
      function($scope, CommunitiesListDataService, $modal, $timeout) {

        $scope.communities = CommunitiesListDataService.communities;
        $scope.slides = [];

        $timeout(function () {
          $scope.assets = _.map(_.filter($scope.communities, function(c) {
            return c.id === 22 || c.id === 30 || c.id === 36 || c.id === 37;
          }), 'assets');

          _.forEach($scope.assets, function(n1, key1) {
            _.forEach(n1, function(n2, key2) {
              $scope.slides.push({
                'image': n2.image.original,
                'active': false
              });
            });
          });
        }, 3000);

        $('.custom-carousel-modal').on('show.bs.modal', function (e) {
          var idx = $(e.relatedTarget).data('idx');

          $('.custom-carousel').carousel(idx);
        });

    }
  ]);
})();