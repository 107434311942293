;(function(){

  'use strict';
  /* global angular */

  angular.module("Milestone")

    .service("TestimonialsListDataService", ["$http", function($http){
      var data = {
        testimonials: []
      };

      // Go fetch the regions from the API
      $http.get('/testimonials')
        .then(function(result){
          angular.forEach(result.data.testimonials, function(r){
            data.testimonials.push(r);
          });
        });

      return {
        testimonials: data.testimonials
      };
    }])

  ;

})();
