angular.module('Milestone')

  .controller('LandingpageCtrl', ['$scope', '$rootScope', '$http', '$window', 'scrollMagic', 'TestimonialsListDataService', 'UserDataService', 'device',
    function ($scope, $rootScope, $http, $window, scrollMagic, TestimonialsListDataService, UserDataService, device) {
      'use strict';
      /* global $, angular, LassoCRM, clicky, clicky_custom, _gaq */

      $scope.testimonials = TestimonialsListDataService.testimonials;

      scrollMagic.scrollTrigger('section', 'header', 'smaller');

      $('#landingpageCarousel .carousel-inner').slick({
        // centerMode: true,
        centerPadding: '60px',
        slidesToShow: 1,
        arrows: false,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              centerPadding: '0px'
            }
          }
        ]
      });

      $scope.slidePrev = function () {
        $('#landingpageCarousel .carousel-inner').slick('slickPrev');
      };

      $scope.slideNext = function () {
        $('#landingpageCarousel .carousel-inner').slick('slickNext');
      };

      $scope.signup = {};
      UserDataService.getUser().then(function (user) {
        $scope.signup = angular.copy(user);
        $scope.signup.origin = window.location.href;
        $scope.signup.type = 'signUp';
        $scope.signup.communities = [];
        $scope.signup.communities[0] = community_id;
        $scope.signup.form_id = 177545;
        $scope.signup.guid = window.getLassoGuid();
      });

      $scope.hideForm = false;
      $scope.showMessage = false;
      $scope.showLoader = false;
      $scope.triedSubmit = false;

      $scope.checkFormValidation = function (form) {
        $scope.triedSubmit = true;

        if (!form.$valid) {
          return;
        }

        processMarketingForm();
      };

      function processMarketingForm () {
        $scope.hideForm = true;
        $scope.showLoader = true;
        $http({
          method: 'POST',
          url: '/form/to-database',
          data: $.param($scope.signup), // pass in data as strings
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          } // set the headers so angular passing info as form data (not request payload)
        })
          .success(function (data) {
            $scope.showLoader = false;
            if (data.status === 'success') {
              // Tie lead id and email to clicky analytics data
              clicky_custom.visitor = {
                lead_id: data.id,
                email: data.email,
                signup: '1'
              };

              clicky_custom.goal = {
                name: 'Quick Signup'
              };

              // Log action in clicky
              clicky.goal('2672'); // Logs a "quick-signup" goal hit in clicky
              clicky.log('/quick-signup/thank-you'); // Logs a fake page hit in clicky

              // Fire off a Facebook Pixel Code (Lead)
              fbq('track', 'Lead');

              // Fire off a Google Analytics goal hit
              _gaq.push(['_trackPageview', '/ajax-goals/quick-signup-landing-page']);

              if (typeof googleConversionId !== 'undefined' && typeof googleConversionLabel !== 'undefined' && googleConversionId && googleConversionLabel) {
                $window.google_trackConversion({
                  google_conversion_id: googleConversionId,
                  google_conversion_language: 'en',
                  google_conversion_format: '3',
                  google_conversion_color: 'ffffff',
                  google_conversion_label: googleConversionLabel,
                  google_remarketing_only: false
                });
              }

              $scope.message = data.message;
              $scope.showMessage = true;
            } else {
              $scope.message = data.message;
              $scope.showMessage = true;
            }
          });
      }
    }
  ])
;
