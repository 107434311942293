(function() {

  'use strict';

  angular.module("Milestone.DataServices")

    .service('RegionDataPromiseService', ['$http',
      function($http) {

        var promise = $http.get('/regions');

        return {
          promise: promise
        };

      }
    ])
  ;

})();
