(function() {

  'use strict';
  /* global $, angular */

  angular
    .module("Milestone")

    .controller("theGroveAtShoalCreekCtrl", ["$scope", 'CommunityService', '$modal',
      function($scope, CommunityService, $modal) {

        $scope.slides = [{
            'image': '/images/site/the-grove-at-shoal-creek/Grove-images-1.jpg',
            'active': false
          }, {
            'image': '/images/site/the-grove-at-shoal-creek/Grove-images-2.jpg',
            'active': false
          }, {
            'image': '/images/site/the-grove-at-shoal-creek/Grove-images-3.jpg',
            'active': false
          }, {
            'image': '/images/site/the-grove-at-shoal-creek/Grove-images-4.jpg',
            'active': false
          }, {
            'image': '/images/site/the-grove-at-shoal-creek/Grove-images-5.jpg',
            'active': false
          }, {
            'image': '/images/site/the-grove-at-shoal-creek/Grove-images-6.jpg',
            'active': false
          }, {
            'image': '/images/site/the-grove-at-shoal-creek/Grove-images-7.jpg',
            'active': false
          }, {
            'image': '/images/site/the-grove-at-shoal-creek/Grove-images-8.jpg',
            'active': false
          }, {
            'image': '/images/site/the-grove-at-shoal-creek/Grove-images-9.jpg',
            'active': false
          }, {
            'image': '/images/site/the-grove-at-shoal-creek/Grove-images-10.jpg',
            'active': false
          }, {
            'image': '/images/site/the-grove-at-shoal-creek/Grove-images-11.jpg',
            'active': false
          }, {
            'image': '/images/site/the-grove-at-shoal-creek/Grove-images-12.jpg',
            'active': false
          }
        ];

        $scope.selectImage = function(idx) {
          var modalInstance = $modal.open({
            animation: true,
            templateUrl: 'myModalContent.html',
            controller: 'ModalInstanceCtrl',
            windowClass: 'custom-carousel-modal',
            size: 'lg',
            resolve: {
              items: function () {
                return $scope.slides;
              },
              index: idx
            }
          });
        };

      }
    ])
    .controller('ModalInstanceCtrl', ["$scope", '$modalInstance', 'items', 'index',
      function ($scope, $modalInstance, items, index) {

        $scope.myInterval = false;
        $scope.noWrapSlides = false;
        $scope.slides = items;
        $scope.slides[index].active = true;

        $scope.cancel = function () {
          items.forEach(function (item, index) {
            item.active = false;
          });
          $modalInstance.dismiss('cancel');
        };

      }
    ]);

})();