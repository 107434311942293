;(function() {
  'use strict';
  /* global angular */

  angular
    .module("Milestone.microsite")

    .controller("AmenitiesCtrl", ['$scope', '$q', '$timeout', 'uiGmapPromises', 'LocalAmenitiesService',
      function($scope, $q, $timeout, uiGmapPromises, LocalAmenitiesService) {

        $scope.localAmenities = LocalAmenitiesService.data;

        $scope.selectedAmenity = $scope.localAmenities[0];

        var communityLocation = null;

        var placesService = null;

        uiGmapPromises
          .GoogleMapsApiReady()
          .then(function(GoogleMaps) {

            $scope.map = {
              center: {
                latitude: $scope.community.latitude,
                longitude: $scope.community.longitude
              },
              control: {},
              markers: [],
              zoom: 13,
              options: {
                streetViewControl: false,
                draggable: true,
                scrollwheel: true,
                mapTypeControl: false,
                panControl: true,
                panControlOptions: {
                  position: GoogleMaps.ControlPosition.LEFT_CENTER
                },
                zoomControl: true,
                zoomControlOptions: {
                  position: GoogleMaps.ControlPosition.LEFT_CENTER
                }
              },
              window: {
                marker: {},
                show: false,
                closeClick: function() {
                  this.show = false;
                  this.model = {};
                },
                options: {
                  maxWidth: 800,
                  pixelOffset: new GoogleMaps.Size(-5, -60)
                }
              },
              markersEvents: {
                click: function(marker, eventName, model, args) {

                  $scope.map.window.show = false;
                  $scope.map.window.marker = marker;
                  $scope.map.window.model = model;
                  $scope.map.window.show = true;

                  $scope.map.window.options = {
                    maxWidth: 800,
                    pixelOffset: new GoogleMaps.Size(-5, -50)
                  };

                  var request = {
                    placeId: model.place_id
                  };

                  placesServicePromiseFactory(request, 'getDetails')
                    .then(function(details) {
                      model.address = details.formatted_address.replace(', United States', '');
                      model.phone = details.formatted_phone_number;

                      model.has_photo = false;
                    })
                    .then(uiGmapPromises.GoogleMapsIsAllReady)
                    .then(function(theMap) {
                      theMap.setCenter({
                        'lat': model.latitude,
                        'lng': model.longitude
                      });

                    });
                }
              }
            };

            communityLocation = new GoogleMaps.LatLng($scope.community.latitude, $scope.community.longitude);

            uiGmapPromises.GoogleMapsIsAllReady()
              .then(function(theMap) {

                // resize trigger
                GoogleMaps.event.addDomListener(window, "resize", function() {
                  theMap.setCenter({
                    'lat': $scope.map.center.latitude,
                    'lng': $scope.map.center.longitude
                  });
                });


                placesService = new GoogleMaps.places.PlacesService($scope.map.control.getGMap());

                var placesSearches = $scope.localAmenities.map(searchForAmenity);

                // when I have all the promises
                $q.all(placesSearches)
                  // after they all resolve, explicitly select "schools", the first amenity in the UI.
                  .then(function() {
                    $scope.selectAmenity($scope.localAmenities[0]);
                  });
                return theMap;
              });
          });

        $scope.selectAmenity = function(amenity) {

          $scope.map.window.marker = {};
          $scope.map.window.show = false;

          $scope.selectedAmenity = amenity;
          $scope.map.markers = [];

          // Force this to happen in the next $digest
          $timeout(function(){
            $scope.map.markers = angular.copy( amenity.pins );
            fitBoundsWithCurrentMarkers();
          }, 1);

        };

        var addMarkerToMap = function(amenity, searchResult) {

          angular.forEach(searchResult, function(place) {

            var amenity_index = $scope.localAmenities.indexOf(amenity);

            var marker = {
              id: amenity_index + place.place_id,
              place_id: place.place_id,
              name: place.name,
              latitude: place.geometry.location.lat(),
              longitude: place.geometry.location.lng(),
              pin: amenity.pin_icon,
              icon: amenity.icon,
              category: amenity_index,
              options: {
                visible: true
              }
            };

            // console.log(place.name, "is a", $scope.localAmenities[amenity_index].name);

            $scope.localAmenities[amenity_index].pins.push(marker);
          });

        };

        var fitBoundsWithCurrentMarkers = function() {

          uiGmapPromises
            .GoogleMapsApiReady()
            .then(function(GoogleMaps) {
              uiGmapPromises
                .GoogleMapsIsAllReady()
                .then(function(theMap) {

                  GoogleMaps.event.trigger(theMap, 'resize');

                  var newBounds = new GoogleMaps.LatLngBounds();

                  //newBounds.extend(new GoogleMaps.LatLng(austin.latitude, austin.longitude));

                  angular.forEach($scope.map.markers, function(marker) {
                    newBounds.extend(new GoogleMaps.LatLng(marker.latitude, marker.longitude));
                    newBounds.extend(new GoogleMaps.LatLng(0.005 + (1 * marker.latitude), 0.005 + (1 * marker.longitude)));
                  });

                  theMap.fitBounds(newBounds);

                  if (theMap.getZoom() > 13) {
                    theMap.setZoom(13);
                  }
                });
            });
        };


        // genereates a promise
        // which resolves the result of a Places search
        var placesServicePromiseFactory = function(request, service) {

          if (service === undefined) {
            service = 'search';
          }

          return $q(function(resolve, reject) {
            placesService[service](request, function(results, status) {
              if (status === 'OK') {
                resolve(results);
              } else {
                reject(status);
              }
            });
          });

        };

        // generates a promise
        // which resolves after adding a marker to the Amenity
        var searchForAmenity = function(amenity) {

          var request = makeRequestObjectFromAmenity(amenity);

          return placesServicePromiseFactory(request)
            .then(function(results) {
              addMarkerToMap(amenity, results);
            })
            .catch(function(status) {
              // Remove the amenity from the list if we don't find any results
              $scope.localAmenities.splice($scope.localAmenities.indexOf(amenity), 1);
            });

        };

        // returns an object
        // for use as a Request to Places Search
        var makeRequestObjectFromAmenity = function(amenity) {
          return {
            location: communityLocation,
            radius: 4000,
            types: amenity.types
          };
        };

      }
    ])
  ;

})();