(function() {

  'use strict';
  /* global $, angular */

  angular.module('Milestone').controller('HomecareTipsCtrl', ['$scope',
    function($scope) {
      $scope.monthList = [
        {
          name: 'January',
          tips: [
            'Air Filters - HVAC',
            'Garage Door Alignment',
            'Flush Disposal',
            'Sink Stopper Function',
            'Tub/Shower Caulk'
          ],
          moreTips: [
            'Air Filters - HVAC',
            'Garage Door Alignment',
            'Flush Disposal',
            'Sink Stopper Function',
            'Tub/Shower Caulk',
            'Irrigation zones/coverage'
          ]
        },
        {
          name: 'February',
          tips: [
            'Air Filters - HVAC',
            'Garage Door Alignment',
            'Flush Disposal',
            'Sink Stopper Function',
            'Tub/Shower Caulk'
          ],
          moreTips: [
            'Air Filters - HVAC',
            'Garage Door Alignment',
            'Flush Disposal',
            'Sink Stopper Function',
            'Tub/Shower Caulk',
            'Irrigation zones/coverage'
          ]
        },
        {
          name: 'March',
          tips: [
            'Air Filters - HVAC',
            'Garage Door Alignment',
            'Flush Disposal',
            'Sink Stopper Function',
            'Tub/Shower Caulk'
          ],
          moreTips: [
            'Air Filters - HVAC',
            'Garage Door Alignment',
            'Flush Disposal',
            'Sink Stopper Function',
            'Tub/Shower Caulk',
            'Irrigation zones/coverage',
            'Exterior Window Caulk',
            'Clean Faucet Aerators',
            'Clean Range Hood Filter',
            'Check Under Cabinet Plumbing Treat A/C drain lines',
            'Drain Water Heater'
          ]
        },
        {
          name: 'April',
          tips: [
            'Air Filters - HVAC',
            'Garage Door Alignment',
            'Flush Disposal',
            'Sink Stopper Function',
            'Tub/Shower Caulk'
          ],
          moreTips: [
            'Air Filters - HVAC',
            'Garage Door Alignment',
            'Flush Disposal',
            'Sink Stopper Function',
            'Tub/Shower Caulk',
            'Irrigation zones/coverage',
            'Drain Water Heater',
            'Seal Grout',
            'HVAC Service',
            'Seal Roof Vent'
          ]
        },
        {
          name: 'May',
          tips: [
            'Air Filters - HVAC',
            'Garage Door Alignment',
            'Flush Disposal',
            'Sink Stopper Function',
            'Tub/Shower Caulk'
          ],
          moreTips: [
            'Air Filters - HVAC',
            'Garage Door Alignment',
            'Flush Disposal',
            'Sink Stopper Function',
            'Tub/Shower Caulk',
            'Irrigation zones/coverage'
          ]
        },
        {
          name: 'June',
          tips: [
            'Air Filters - HVAC',
            'Garage Door Alignment',
            'Flush Disposal',
            'Sink Stopper Function',
            'Tub/Shower Caulk'
          ],
          moreTips: [
            'Air Filters - HVAC',
            'Garage Door Alignment',
            'Flush Disposal',
            'Sink Stopper Function',
            'Tub/Shower Caulk',
            'Irrigation zones/coverage',
            'Exterior Window Caulk',
            'Clean Faucet Aerators',
            'Clean Range Hood Filter',
            'Check Under Cabinet Plumbing Treat A/C drain lines',
            'Drain Water Heater'
          ]
        },
        {
          name: 'July',
          tips: [
            'Air Filters - HVAC',
            'Garage Door Alignment',
            'Flush Disposal',
            'Sink Stopper Function',
            'Tub/Shower Caulk'
          ],
          moreTips: [
            'Air Filters - HVAC',
            'Garage Door Alignment',
            'Flush Disposal',
            'Sink Stopper Function',
            'Tub/Shower Caulk',
            'Irrigation zones/coverage'
          ]
        },
        {
          name: 'August',
          tips: [
            'Air Filters - HVAC',
            'Garage Door Alignment',
            'Flush Disposal',
            'Sink Stopper Function',
            'Tub/Shower Caulk'
          ],
          moreTips: [
            'Air Filters - HVAC',
            'Garage Door Alignment',
            'Flush Disposal',
            'Sink Stopper Function',
            'Tub/Shower Caulk',
            'Irrigation zones/coverage'
          ]
        },
        {
          name: 'September',
          tips: [
            'Air Filters - HVAC',
            'Garage Door Alignment',
            'Flush Disposal',
            'Sink Stopper Function',
            'Tub/Shower Caulk'
          ],
          moreTips: [
            'Air Filters - HVAC',
            'Garage Door Alignment',
            'Flush Disposal',
            'Sink Stopper Function',
            'Tub/Shower Caulk',
            'Irrigation zones/coverage',
            'Exterior Window Caulk',
            'Clean Faucet Aerators',
            'Clean Range Hood Filter',
            'Check Under Cabinet Plumbing Treat A/C drain lines',
            'Drain Water Heater'
          ]
        },
        {
          name: 'October',
          tips: [
            'Air Filters - HVAC',
            'Garage Door Alignment',
            'Flush Disposal',
            'Sink Stopper Function',
            'Tub/Shower Caulk'
          ],
          moreTips: [
            'Air Filters - HVAC',
            'Garage Door Alignment',
            'Flush Disposal',
            'Sink Stopper Function',
            'Tub/Shower Caulk',
            'Irrigation zones/coverage'
          ]
        },
        {
          name: 'November',
          tips: [
            'Air Filters - HVAC',
            'Garage Door Alignment',
            'Flush Disposal',
            'Sink Stopper Function',
            'Tub/Shower Caulk'
          ],
          moreTips: [
            'Air Filters - HVAC',
            'Garage Door Alignment',
            'Flush Disposal',
            'Sink Stopper Function',
            'Tub/Shower Caulk',
            'Irrigation zones/coverage'
          ]
        },
        {
          name: 'December',
          tips: [
            'Air Filters - HVAC',
            'Garage Door Alignment',
            'Flush Disposal',
            'Sink Stopper Function',
            'Tub/Shower Caulk'
          ],
          moreTips: [
            'Air Filters - HVAC',
            'Garage Door Alignment',
            'Flush Disposal',
            'Sink Stopper Function',
            'Tub/Shower Caulk',
            'Irrigation zones/coverage',
            'Exterior Window Caulk',
            'Clean Faucet Aerators',
            'Clean Range Hood Filter',
            'Check Under Cabinet Plumbing Treat A/C drain lines',
            'Drain Water Heater'
          ]
        }
      ];

      $(document).ready(function() {
        var modalsEl = $('.homecare-tips .modal');
        var bodyEl = $('body');

        // Attach click event listener to close modal when clicking outside of it.
        modalsEl.on('show.bs.modal', function() {
          bodyEl.on('click.closeModal', function(event) {
            // Prevent modal from closing if clicking inside the modal
            if (modalsEl.find(event.target).length === 0) {
              // Hide open modal
              modalsEl.modal('hide');

              // Remove click event listener
              bodyEl.off('click.closeModal');
            }
          });
        });
      });
    }
  ])
    .directive('resizeImage', [function() {
      return {
        restrict: 'A',
        link: function(scope, elem, iAttrs) {

          $(document).ready(resizer);
          $(window).resize(resizer);

          function resizer() {
            var width = $(window).width();

            var calcLeft = [50, 20, 40];
            var calcRight = [28, 10, 20];

            if (width > 1200) {
              calcRight[0] = 28;
            }

            if (width > 1400) {
              calcRight = [27, 20, 25];
            }

            if (width > 1700) {
              calcLeft = [49, 45, 70];
              calcRight = [calcRight[0], 28, 27];
            }

            if (width > 1900) {
              calcLeft = [48, 53, 85];
              calcRight = [23, 55, 44];
            }

            if (width > 2500) {
              calcLeft = [47, 65, 100];
              calcRight = [20, 70, 55];
            }

            if (elem.hasClass('left')) {
              elem.css({
                left: 0,
                top: calcLeft[0] + "%",
                marginLeft: -calcLeft[1] + "%",
                width: calcLeft[2] + "%"
              });
            } else {
              elem.css({
                right: 0,
                top: calcRight[0] + "%",
                marginRight: -calcRight[1] + "%",
                width: calcRight[2] + "%"
              });
            }
          }
        }
      };
    }])
  ;

})();
