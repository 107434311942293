angular
  .module("Milestone")

  .service('HomeDataService', ['$http', '$q', 'HomeDataCache',
    function($http, $q, HomeDataCache) {
      'use strict';

      return {
        'home': function(id) {
          return $q(function(resolve) {
            resolve(HomeDataCache.home);
          });
        }
      };

    }
  ])

;