;(function() {
  'use strict';
  /* global angular */

  angular
    .module("Milestone.microsite")

    .controller("CommunityLocationCtrl", ['$scope', '$q', '$timeout', 'uiGmapPromises', 'CommunitiesListDataService',
      function($scope, $q, $timeout, uiGmapPromises, CommunitiesListDataService) {

        $scope.isKey = false;

        uiGmapPromises
          .GoogleMapsApiReady()
          .then(function(GoogleMaps) {

            $scope.map = {
              center: {
                latitude: $scope.community.sales_office_latitude,
                longitude: $scope.community.sales_office_longitude
              },
              control: {},
              markers: [
                {
                  id: 0,
                  latitude: $scope.community.sales_office_latitude,
                  longitude: $scope.community.sales_office_longitude,
                  icon: '/images/site/pin-blue.png',
                  options: {
                    visible: true
                  }
                }
              ],
              zoom: 15,
              options: {
                streetViewControl: false,
                draggable: true,
                scrollwheel: true,
                mapTypeControl: false,
                panControl: true,
                panControlOptions: {
                  position: GoogleMaps.ControlPosition.LEFT_CENTER
                },
                zoomControl: true,
                zoomControlOptions: {
                  position: GoogleMaps.ControlPosition.LEFT_CENTER
                }
              },
              windows: [
                {
                  id: 0,
                  latitude: $scope.community.sales_office_latitude,
                  longitude: $scope.community.sales_office_longitude,
                  show: false,
                  templateUrl: 'communityLocationPopupWindow.html',
                  templateParameter: {
                    name: 'Sales Center',
                    latitude: $scope.community.sales_office_latitude,
                    longitude: $scope.community.sales_office_longitude
                  },
                  options: {
                    maxWidth: 800,
                    pixelOffset: new GoogleMaps.Size(-7, -50)
                  }
                }
              ],
              markersEvents: {
                click: function(marker, eventName, model, args) {

                  $scope.map.windows[model.id].show = true;

                }
              }
            };

            if ($scope.community.latitude && $scope.community.longitude) {
                if (parseFloat($scope.community.latitude) === parseFloat($scope.community.sales_office_latitude) && parseFloat($scope.community.longitude) === parseFloat($scope.community.sales_office_longitude)) {
                  $scope.map.markers[0].icon = '/images/site/pin-red.png';
                  $scope.map.windows[0].templateParameter.name = 'Community Location and Sales Center';
                } else {
                  $scope.isKey = true;
                  $scope.map.markers.push({
                    id: 1,
                    latitude: $scope.community.latitude,
                    longitude: $scope.community.longitude,
                    icon: '/images/site/pin-red.png',
                    options: {
                      visible: true
                    }
                  });
                  $scope.map.windows.push({
                    id: 1,
                    latitude: $scope.community.latitude,
                    longitude: $scope.community.longitude,
                    show: false,
                    templateUrl: 'communityLocationPopupWindow.html',
                    templateParameter: {
                      name: 'Community Location',
                      latitude: $scope.community.latitude,
                      longitude: $scope.community.longitude
                    },
                    options: {
                      maxWidth: 800,
                      pixelOffset: new GoogleMaps.Size(-7, -50)
                    }
                  });
                }
            }

            uiGmapPromises.GoogleMapsIsAllReady()
              .then(function(theMap) {

                // resize trigger
                GoogleMaps.event.addDomListener(window, "resize", function() {
                  theMap.setCenter({
                    'lat': $scope.map.center.latitude,
                    'lng': $scope.map.center.longitude
                  });
                });

                var newBounds = new GoogleMaps.LatLngBounds();

                angular.forEach($scope.map.markers, function(marker) {
                  newBounds.extend(new GoogleMaps.LatLng(marker.latitude, marker.longitude));
                  newBounds.extend(new GoogleMaps.LatLng(0.005 + (1 * marker.latitude), 0.005 + (1 * marker.longitude)));
                });

                $timeout(function(){
                  theMap.fitBounds(newBounds);

                  if (theMap.getZoom() > 14) {
                    theMap.setZoom(14);
                  }
                }, 1);

                return theMap;
              });
          });

      }
    ])
  ;

})();