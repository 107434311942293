(function() {

  'use strict';
  /* global angular */

  angular
    .module("Milestone")
    .controller("ParallaxCtrl", function($scope, parallaxHelper) {
      // $scope.bgslidercarousel = parallaxHelper.createAnimator(-0.9);
      // $scope.backgroundcommunity = parallaxHelper.createAnimator(-0.2, 400, -400);
      // $scope.background = parallaxHelper.createAnimator(-0.3, 200, -50); //-0.16, 300 , -300
      // $scope.backgroundaccolades = parallaxHelper.createAnimator(-0.25, 75, -55);

      // var is_mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

      // window.onscroll = function(e) {
      // 	parallax_scroll();
      // };

      // //parallax data attr{top,speed,height}
      // function parallax_set(){
      // 	if(!is_mobile){
      // 		$('.parallax-ms').each(function(e){
      // 			var top = $(this).data('top');
      // 			var height = $(this).data('height');
      // 			if(top == "auto"){
      // 				top = $(this).parent().height()/2.2;
      // 			}
      // 			$(this).css({
      // 				'top': top,
      // 				'height': height
      // 			});
      // 			$(this).parent().css('overflow','hidden');
      // 		});
      // 	}
      // }

      // function parallax_scroll(){
      // 	if(!is_mobile){
      // 		var $parallax = $('.parallax-ms');
      // 		if ($parallax !== null){
      // 			console.log($parallax);
      // 			$parallax.each(function(){
      // 				var y = window.pageYOffset;
      // 				var result = $(this).data('speed');

      // 				console.log('y: ',y);
      // 				console.log('result: ', result);
      // 				$(this).css('transform','translate3d(0px,' + -window.pageYOffset/$(this).data('speed') + 'px, 0px)');
      // 			});
      // 		}
      // 	}
      // }
    })
  ;

})();
