(function() {

  'use strict';
  /* global $, angular */

  angular.module("Milestone").controller("awardCtrl", ["$scope",
    function($scope) {
      $(document).ready(function() {
        $('#awardsCarousel').addClass('fade-in');
      });
    }
  ]);

})();
