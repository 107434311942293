(function() {

  'use strict';
  /* global angular, ScrollMagic, TweenMax */

  angular.module("Milestone").directive('mobileSetActive', function() {
    return function(scope, elem, attrs) {

      if($(attrs.check).length){
        elem.addClass('active');
      }

    };
  });

})();
