(function() {

  'use strict';
  /* global $, angular */

  angular.module('Milestone.Slideshow')

    .service('msSlideShowDataService', [function() {
      var data = {};

      data.gallery = [];

      data.selected = 0;

      data.select = function(image) {
        data.selected = data.gallery.indexOf(image);
      };

      data.getImage = function(){
        return data.gallery[data.selected];
      };

      data.prev = function(){
        data.selected = data.selected - 1;
        if (data.selected <= 0) {
          data.selected = 0;
        }
      };

      data.next = function(){
        data.selected = data.selected + 1;
        if (data.selected >= data.gallery.length - 1) {
          data.selected = data.gallery.length - 1;
        }
      };

      return data;
    }])

    .directive('msSlideShow', [function() {

      return {
        templateUrl: 'ms-slide-show',
        restrict: 'E',
        controller: ['$scope', 'msSlideShowDataService', function($scope, msSlideShowDataService){
          $scope.active = function(){
            return msSlideShowDataService.getImage();
          };

          $scope.slides = msSlideShowDataService.gallery;

          $scope.btnPrev = function() {
            msSlideShowDataService.prev();
          };

          $scope.btnNext = function() {
            msSlideShowDataService.next();
          };

        }],
        link: function(scope, element, attrs) {

          var el = $(element[0]);

          el.hide();


          scope.$on('msSlideShow:showSlideShow', function() {
            showSlideShow();
          });

          function showSlideShow() {
            el.fadeIn(200, function() {
              el.show();
            });

            $('body').addClass('body-no-scroll');
          }

          scope.btnClose = function() {
            el.fadeOut(100, function() {
              el.hide();
            });

            $('body').removeClass('body-no-scroll');
          };

        }
      };

    }]);

})();
