;(function() {
  'use strict';
  /* global angular */

  angular
    .module("Milestone.microsite")


    // this has to be a sub-controfller of FloorPlansTabsCtrl
    .controller("FloorPlansCtrl", ['$scope', 'device', function($scope, device) {

      $scope.naturalSort = function(community) {
        return community.name.replace(/^The /, '');
      };

    }])

    .controller("FloorPlansElevationsCarouselCtrl", ['$scope', function($scope) {

      $scope.activeImage = 0;

      $scope.$on("floorplans:changedhome", function() {
        // This is here because when we switch plans, if we are on img#4
        // and the new plan only has 2 images, we will get a white screen. -wo
        $scope.activeImage = 0;
      });

      $scope.next = function() {
        if ($scope.activeImage === $scope.floorplanElevation.elevations.length - 1) {
          $scope.activeImage = 0;
        } else {
          $scope.activeImage = $scope.activeImage + 1;
        }
      };

      $scope.prev = function() {
        if ($scope.activeImage === 0) {
          $scope.activeImage = $scope.floorplanElevation.elevations.length - 1;
        } else {
          $scope.activeImage = $scope.activeImage - 1;
        }
      };

    }])

    // this has to be a sub-controller of FloorPlansTabsCtrl
    .controller("AvailableHomesCtrl", ['$scope', '$window', '$modal', 'FloorPlanDataService', function($scope, $window, $modal, FloorPlanDataService) {

      $scope.data = FloorPlanDataService.data;

      $scope.homesOfTheWeek = $scope.data.homeWeek;

      $scope.availableHomes = $scope.data.availableHomes;

      switch ($scope.community.sort_method) {
        case 'availability-ascending':
          $scope.sortColumn = ['!is_completed', 'estimated_completion_date', 'id'];
          $scope.reverse = false;
          break;
        case 'availability-descending':
          $scope.sortColumn = ['!is_completed', 'estimated_completion_date', 'id'];
          $scope.reverse = true;
          break;
        case 'plan-ascending':
          $scope.sortColumn = 'residence.name';
          $scope.reverse = false;
          break;
        case 'plan-descending':
          $scope.sortColumn = 'residence.name';
          $scope.reverse = true;
          break;
        case 'price-ascending':
          $scope.sortColumn = 'price';
          $scope.reverse = false;
          break;
        case 'price-descending':
          $scope.sortColumn = 'price';
          $scope.reverse = true;
          break;
        case 'sqft-ascending':
          $scope.sortColumn = 'sqft';
          $scope.reverse = false;
          break;
        case 'sqft-descending':
          $scope.sortColumn = 'sqft';
          $scope.reverse = true;
          break;
        case 'beds-ascending':
          $scope.sortColumn = 'beds';
          $scope.reverse = false;
          break;
        case 'beds-descending':
          $scope.sortColumn = 'beds';
          $scope.reverse = true;
          break;
        case 'baths-ascending':
          $scope.sortColumn = 'baths';
          $scope.reverse = false;
          break;
        case 'baths-descending':
          $scope.sortColumn = 'baths';
          $scope.reverse = true;
          break;
        case 'garage-ascending':
          $scope.sortColumn = 'garage';
          $scope.reverse = false;
          break;
        case 'garage-descending':
          $scope.sortColumn = 'garage';
          $scope.reverse = true;
          break;
        default:
          $scope.sortColumn = ['!is_completed', 'estimated_completion_date', 'id'];
          $scope.reverse = false;
          break;
      }

      $scope.chooseElevation = function(home, size) {

        if (typeof size !== 'undefined') {
          size = size;
        } else {
          size = 'medium';
        }

        if (home.assets.length > 0) {
          return _.get(home.assets[0].image, size);
        }

        if (home.elevation && _.get(home.elevation.img, size) !== undefined) {
          return _.get(home.elevation.img, size);
        }

        if (home.residence && home.residence.elevations[0] !== undefined && _.get(home.residence.elevations[0].img, size) !== undefined) {
          return _.get(home.residence.elevations[0].img, size);
        }

        // really really default
        return "/images/site/microsite/site-plan-popover-thumb.png";

      };

      // got to use this instead of == to support arrays
      var array_equals = function(a, b) {
        if (typeof a === 'string') {
          return a === b;
        }

        return a.length === b.length && a.every(function(v, i) {
            return v === b[i];
          });
      };

      $scope.sort = function(column) {

        if (array_equals($scope.sortColumn, column)) {
          $scope.reverse = !$scope.reverse;
          return;
        }

        $scope.sortColumn = column;
        $scope.reverse = false;
      };

      $scope.sortArrows = function(column) {
        var asc = array_equals($scope.sortColumn, column) && !$scope.reverse;
        var desc = array_equals($scope.sortColumn, column) && $scope.reverse;

        var res = {
          'indicator': true,
          'indicator-asc': asc,
          'indicator-desc': desc
        };

        return res;
      };

      $scope.dropdownList = [
        {
          filterName: 'Plan Name (A-Z)',
          filterBy: 'name',
          sort: 'asc'
        },
        {
          filterName: 'Plan Name (Z-A)',
          filterBy: 'name'
        },
        {
          filterName: 'Price (low to high)',
          filterBy: 'price'
        },
        {
          filterName: 'Price (high to low)',
          filterBy: 'price'
        },
        {
          filterName: 'Sqft (low to high)',
          filterBy: ['sqft_low','sqft_high']
        },
        {
          filterName: 'Sqft (high to low)',
          filterBy: ['sqft_low','sqft_high']
        },
        {
          filterName: 'Bed (low to high)',
          filterBy: ['bed_low','bed_high']
        },
        {
          filterName: 'Bed (high to low)',
          filterBy: ['bed_low','bed_high']
        },
        {
          filterName: 'Bath (low to high)',
          filterBy: ['bath_low','bath_high']
        },
        {
          filterName: 'Bath (high to low)',
          filterBy: ['bath_low','bath_high']
        },
        {
          filterName: 'Availability (low to high)',
          filterBy: 'availability',
          sort: 'asc'
        },
        {
          filterName: 'Availability (high to low)',
          filterBy: 'availability'
        }
      ];

      $scope.open = function (home) {
        if (home.activate_details_page) {
          $window.open(home.url, '_blank');
          return;
        }

        $scope.home = home;
        $scope.seo_name = home.residence.seo_name;

        var modalInstance = $modal.open({
          templateUrl: 'availableHomeModalContent.html',
          controller: 'AvailableHomeModalInstanceCtrl',
          windowClass: 'modal-available-home',
          animation: true,
          scope: $scope
        });
      }

      $scope.gallery = function(home) {
          $scope.home = home;

          var modalInstance = $modal.open({
            animation: true,
            templateUrl: 'myModalContent.html',
            controller: 'ModalInstanceCtrl',
            windowClass: 'custom-carousel-modal',
            size: 'lg',
            scope: $scope
          });
        };

    }])

    .controller('AvailableHomeModalInstanceCtrl', ['$scope', '$modalInstance', function ($scope, $modalInstance) {
      var seo_name = $scope.seo_name;

      if ($scope.homeCards === undefined) {
        return null;
      }

      var match = $scope.homeCards.filter(function(card){
        if (card.seo_name === seo_name) {
          return card;
        }
      });

      $scope.floorplanElevation = match.pop();
      $scope.images = $scope.floorplanElevation.elevations;

      $scope.activeTab = 'floorplans';

      $scope.ok = function () {
        $modalInstance.close();
      };
    }])

    .controller('ModalInstanceCtrl', ["$scope", '$modalInstance',
      function ($scope, $modalInstance) {

        $scope.myInterval = false;
        $scope.noWrapSlides = false;

        $scope.cancel = function () {
          $modalInstance.dismiss('cancel');
        };

      }
    ])

    .controller("FloorPlansTabsCtrl", ['$scope', '$rootScope', '$location', 'FloorPlanDataService', '$routeParams', '$timeout',
      function($scope, $rootScope, $location, FloorPlanDataService, $routeParams, $timeout) {

        /*
          Tabs for main tabs (floorplans and available homes)
        */
        $scope.tabs = {
          floorplans: $scope.community.homes.length > 0 ? false : true,
          availablehomes: $scope.community.homes.length > 0 ? true : false
        };

        this.subMenuFloorplan = true;

        $scope.data = FloorPlanDataService.data;
        $scope.homeCards = $scope.data.homeCards;

        $scope.toggleTab = function(tabName, tabId){
          $scope.hideFloorplan();
          $location.url('floorplans');

          var elem = $(tabName.currentTarget);
          elem.addClass('active').siblings().removeClass('active');

          $scope.residenceSeriesId = tabId;
        }

        $scope.showFloorplan = function(seo_name) {

          // set location if we need to, sometimes this can get called directly....
          if ($location.url() !== 'floorplans/' + seo_name) {
            $location.url('floorplans/' + seo_name);
          }

          $scope.tabs.floorplans = true;
          $scope.tabs.availablehomes = false;

          if ($scope.homeCards === undefined) {
            return null;
          }

          var match = $scope.homeCards.filter(function(card){
            if (card.seo_name === seo_name) {
              return card;
            }
          });

          $scope.floorplanElevation = match.pop();
          $scope.images = $scope.floorplanElevation.elevations;
        };

        $scope.hideFloorplan = function(){
          $scope.floorplanElevation = false;
          $scope.images = [];
        };

        $scope.routeParams = $routeParams;

        $scope.$on("$locationChangeSuccess", function(event, now, was) {
          $timeout(function(){
            if ($scope.routeParams.page === 'floorplans') {
              $scope.tabs.floorplans = true;
              $scope.tabs.availablehomes = false;
            }
            else if ($scope.routeParams.page === 'available-homes') {
              $scope.tabs.floorplans = false;
              $scope.tabs.availablehomes = true;
            }

            if ($scope.routeParams.floorplan) {
              $scope.showFloorplan($scope.routeParams.floorplan);
            }
            else {
              $scope.hideFloorplan();
            }
          });
        });
      }
    ])
  ;

})();
