angular

  .module("common")

  .directive('clickThrough', [ function() {
    'use strict';
    return function(scope, elem, attrs) {
      elem.on('mouseenter', function() {
          elem.addClass('hover-active');
      });
      elem.on('mouseleave', function() {
          elem.removeClass('hover-active');
      });
      
      elem.on('click', function() {
        var url = $(elem).find('a').attr('href');
        window.location.href = url;
      });
    };
  }])
;
