angular.module('Milestone')

  .controller('FullinterestListSignupCtrl', ['$scope', '$http', '$filter', 'CommunitiesListDataService', 'UserDataService',
    function ($scope, $http, $filter, CommunitiesListDataService, UserDataService) {
      'use strict';
      /* global LassoCRM, clicky, clicky_custom, _gaq */

      $scope.regions = CommunitiesListDataService.regions;
      $scope.hears = {
        columnOne: [
          {
            id: 'internet-search',
            name: 'Internet Search'
          },
          {
            id: 'real-estate-professional',
            name: 'Real Estate Professional'
          },
          {
            id: 'direct-mail',
            name: 'Direct Mail'
          }
        ],
        columnTwo: [
          {
            id: 'print-ad-referral',
            name: 'Print Ad Referral'
          },
          {
            id: 'internet-banner-ad',
            name: 'Internet Banner Ad'
          },
          {
            id: 'from-a-friend',
            name: 'From a Friend'
          }
        ],
        columnThree: [
          {
            id: 'live-in-area',
            name: 'Live in Area'
          },
          {
            id: 'signs-drive-by',
            name: 'Signs/Drive By'
          },
          {
            id: 'other',
            name: 'Other'
          }
        ]
      };

      // toggle hears selections
      $scope.toggleHearSelection = function toggleHearSelection (id) {
        var idx = $scope.signup.hears.indexOf(id);
        // is currently selected, remove it
        if (idx > -1) {
          $scope.signup.hears.splice(idx, 1);
        }
        // is newly selected, add it
        else {
          $scope.signup.hears.push(id);
        }

        checkOther();
      };

      var checkOther = function checkOther () {
        $scope.otherChecked = $scope.signup.hears.indexOf('other') > -1;
        if (!$scope.otherChecked) {
          delete $scope.signup.other;
        }
      };

      $scope.signup = {};
      UserDataService.getUser().then(function (user) {
        $scope.signup = angular.copy(user);
        $scope.signup.opt_in = 1;
        $scope.signup.origin = window.location.href;
        $scope.signup.type = 'signUp';
        $scope.signup.communities = [];
        $scope.signup.form_id = 177542;
        $scope.signup.guid = window.getLassoGuid();
        $scope.signup.hears = [];
      });

      $scope.hideForm = false;
      $scope.showMessage = false;
      $scope.showLoader = false;
      $scope.noCommunities = false;
      $scope.noDate = false;
      $scope.triedSubmit = false;

      $scope.noDate = function () {
        return $scope.signup.move_in_date === null;
      };

      $scope.noCommunities = function () {
        return ($scope.signup.communities.length === 0);
      };

      $scope.checkFormValidation = function (form) {
        $scope.triedSubmit = true;

        if (!form.$valid) {
          return;
        }

        if ($scope.noCommunities()) {
          return;
        }

        if ($scope.noDate()) {
          console.log('Logging: ', $scope.fullInterestList.move_in_dave);
          return;
        }
        processInterestListForm();
      };

      function processInterestListForm () {
        $scope.hideForm = true;
        $scope.showLoader = true;
        $http({
          method: 'POST',
          url: '/form/to-database',
          data: $.param($scope.signup), // pass in data as strings
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          } // set the headers so angular passing info as form data (not request payload)
        })
          .success(function (data) {
            $scope.showLoader = false;
            if (data.status === 'success') {
              // Tie lead id and email to clicky analytics data
              clicky_custom.visitor = {
                lead_id: data.id,
                email: data.email,
                signup: '1'
              };

              clicky_custom.goal = {
                name: 'Interest List Signup'
              };

              // Log action in clicky
              clicky.goal('2673'); // Logs a "quick-signup" goal hit in clicky
              clicky.log('/interest-list/thank-you'); // Logs a fake page hit in clicky

              // Fire off a Facebook Pixel Code (Lead)
              fbq('track', 'Lead');

              // Fire off a Google Analytics goal hit
              _gaq.push(['_trackPageview', '/ajax-goals/interest-list-signup']);

              $scope.message = data.message;
              $scope.showMessage = true;
            } else {
              $scope.message = data.message;
              $scope.showMessage = true;
            }
          });
      }
    }
  ])

;
