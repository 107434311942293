(function() {

  'use strict';
  /* global $, angular */

  angular.module('Milestone.Forms')
    .directive('msFormValidation', function() {
      return {
        restrict: 'E',
        scope: {
          triedSubmit: '=',
          formElement: '=',
          noDate: '=',
          noCommunities: '=',
          emailsMatch: '='
        },
        templateUrl: 'ms-form-validation.html',
        controller: [ "$scope", function($scope) {
        }]
      };
    });

})();
