(function() {

  'use strict';
  /* global $, angular */

  angular.module("Milestone").directive('bsToggle', function() {
    return {
      restrict: 'A',
      link: function(scope, elem, attrs) {
        elem = $(elem);
        if (attrs.bsToggle === "tooltip") {
          elem.tooltip();
        }

        if (attrs.tooltipActive.length) {
          elem.on('mouseenter', function() {
            toggleText(elem);
          });

          elem.on('click', function() {
            elem.toggleClass('active');
            toggleText(elem);
          });
        }

        if (attrs.bsToggle === "popover") {
          elem.popover();
        }

        function toggleText(elem) {
          var $tooltip = elem.next('.tooltip').find('.tooltip-inner');
          if (elem.hasClass('active')) {
            $tooltip.text(attrs.altTitle);
          } else {
            $tooltip.text(attrs.title);
          }
        }

      }
    };
  });
})();
