(function() {

  'use strict';
  /* global $, angular */

  angular.module('Milestone').controller('TheTeamCtrl', ['$scope', '$modal',
    function($scope, $modal) {

      $scope.members = [
        {
          id: 1,
          photo: '/images/site/why-milestone/photo-teams.jpg',
          name: 'GARRETT MARTIN',
          title: 'PRESIDENT AND CEO',
          blurb: 'Garrett Martin, one of the company’s co-founders, is the President and CEO of MileStone Community Builders.',
          summary: 'Garrett Martin, one of the company’s co-founders, is the President and CEO of MileStone Community Builders. Prior to MileStone, Garrett started his career in home building in the land development section of D.R. Horton and was later selected to help start the Austin division of another national home building company, which experienced great success during its launch.   Garrett attended Trinity University in San Antonio graduating with honors in finance and English literature.  He received his JD after studying at both Southern Methodist University Dedman School of Law and the University Of Texas School of Law. He was a member of the Phi Delta Phi honor society and was on law review at SMU. Garrett enjoys spending time with his beautiful wife and two wonderful children.'
        }
        /*
        {
          id: 2,
          photo: '/images/site/why-milestone/photo-teams.jpg',
          name: 'PAUL CAREY',
          title: 'DIVISION PRESIDENT',
          blurb: 'Paul Carey joined MileStone Community Builders in 2019 as Division President.',
          summary: 'Paul Carey joined MileStone Community Builders in 2019 as Division President. Paul graduated from Stanford University in 1990, where he was a 3 time All-American and was a member of 2 National Championship baseball teams. After playing and coaching in professional baseball for 15 years, Paul began his homebuilding career in 2005 with Centex Homes. Paul brings a leadership style which focuses on people, operational excellence, and an exceptional customer experience.'
        },
        {
          id: 3,
          photo: '/images/site/why-milestone/photo-teams.jpg',
          name: 'STEVE WALKUP',
          title: 'VICE PRESIDENT OF LAND DEVELOPMENT',
          blurb: 'Steve Walkup leads a team of internal project managers and external professional services to develop MileStone communities.',
          summary: 'Steve Walkup leads a team of internal project managers and external professional services to develop MileStone communities. In Steve’s previous employment at DR Horton and Centex homes, Steve was instrumental in developing several large master-planned communities within the Greater San Antonio area; namely Alamo Ranch, Lost Creek, Wildhorse, Kensington Ranch, Retama Springs, and Redbird Ranch.'
        },
        {
          id: 4,
          photo: '/images/site/why-milestone/photo-teams.jpg',
          name: 'GREG MILLER',
          title: 'VICE PRESIDENT OF SALES',
          blurb: 'Greg Miller has 27 years of experience in sales including 11 years managing new home sales teams.',
          summary: 'Greg Miller has 27 years of experience in sales including 11 years managing new home sales teams. Greg found his first success selling homes in July of 2002 for Streetman Homes in Austin, Texas. In his initial year, he delivered 37% of all Streetman Homes sales and was later promoted to Sales Manager. In 2009, Greg joined DR Horton as a Sales Agent in Manor, Texas. Greg became the top Sales Agent in the United States with 137 closings in one year and broke the monthly sales record of 36 net sales. MileStone Community Builders welcomed Greg in November of 2011 as their Director of Sales. Greg believes that success in sales is a result of following a process that he has crafted, honed, and taught throughout his career.'
        },
        {
          id: 5,
          photo: '/images/site/why-milestone/photo-teams.jpg',
          name: 'TERRY LAGRONE',
          title: 'VICE PRESIDENT OF ACCOUNTING',
          blurb: 'Terry joined MileStone Community Builders in 2019 as Vice President of Accounting.',
          summary: 'Terry joined MileStone Community Builders in 2019 as Vice President of Accounting. Terry has 21 years of experience in the homebuilding industry in accounting and land acquisition with D.R. Horton, Taylor Woodrow, KB Home, and Lennar.  Most recently he worked to start up RSI Communities in Austin/San Antonio which was acquired by Lyon Homes and closed 900 homes in 2019.  Terry attended and graduated from Texas State University with a BBA in Accounting and a MBA.'
        },
        {
          id: 6,
          photo: '/images/site/why-milestone/photo-teams.jpg',
          name: 'JARED GLAZAR',
          title: 'DIRECTOR OF MARKETING',
          blurb: 'Jared is responsible for MileStone’s customer brand experience and driving customer interest in our product offerings.',
          summary: 'Jared is responsible for MileStone’s customer brand experience and driving customer interest in our product offerings. Before MilStone, Jared worked as Director of Marketing at Fox Searchlight Pictures in Los Angeles, CA where his duties included creating and overseeing multi-million dollar media campaigns for eight to ten theatrical campaigns a year, researching competitive titles, and collaborating with distribution to determine each film’s roll-out strategy. He has a Television, Radio, and Film degree from the SI Newhouse School of Public Communications at Syracuse University, and in 2014 received his MBA from the Anderson School of Management at UCLA.'
        }
        */
      ];

      $scope.openMember = function (member_id) {
        var properties = {};

        if (member_id) properties.id = member_id;

        $scope.member = _.find($scope.members, function (member) {
            if (_.matches(properties)(member)) {
              return member;
            }
        });
        
        var modalInstance = $modal.open({
          animation: true,
          templateUrl: 'member.html',
          controller: 'memberModalCtrl',
          windowClass: 'modal-member',
          size: 'lg',
          scope: $scope
        });

      };

    }
  ])
  .controller('memberModalCtrl', ['$scope', '$modalInstance',
    function ($scope, $modalInstance) {

      $scope.close = function () {
        $modalInstance.close();
      };

    }]);

})();
