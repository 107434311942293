(function() {

  'use strict';
  /* global angular */

  angular.module("Milestone").directive('carouselFlip', function() {
    return function(scope, elem, attrs) {
      elem.on('slide.bs.carousel', function() {
        elem.find('.item:not(.active)').css('z-index', 0);
        elem.find('.item.active').css('z-index', 1);
      });
      elem.on('slid.bs.carousel', function() {
        elem.find('.item.active').css('z-index', 2);
        elem.find('.item.active .step-number-holder').css({
          'top': '100%',
          'background-color': 'rgb(241,164,60)'
        }).velocity({
          top: '0%'
        }, 600);
      });
    };
  });

})();
