(function () {
  'use strict';
  /* global $, angular, clicky, clicky_custom, _gaq */

  angular.module('Milestone').controller('contactCtrl', ['$scope', '$http', '$timeout', 'RegionsDataService', 'UserDataService', 'CommunitiesListDataService',
    function ($scope, $http, $timeout, RegionsDataService, UserDataService, CommunitiesListDataService) {
      $scope.regions = [];
      RegionsDataService.regions
        .then(function(regions){
          $scope.regions = _.filter(regions, function (region) {
            return region.communities.length > 0;
          });
        });

      UserDataService.getUser().then(function (user) {
        $scope.signup = angular.copy(user);
        $scope.signup.opt_in = 1;
        $scope.signup.origin = window.location.href;
        $scope.signup.type = 'stayInTouch';
        $scope.signup.communities = [];
        $scope.signup.form_id = 177545;
        $scope.signup.guid = window.getLassoGuid();
      });

      $scope.signup = {};

      $scope.hideForm = false;
      $scope.showMessage = false;
      $scope.showLoader = false;
      $scope.noCommunities = false;
      $scope.triedSubmit = false;

      $scope.noCommunities = function () {
        return ($scope.signup.communities.length === 0);
      };

      $scope.checkFormValidation = function (form) {
        $scope.triedSubmit = true;

        if (!form.$valid) {
          return;
        }

        if ($scope.noCommunities()) {
          return;
        }
        processContactUsForm();
      };

      function processContactUsForm () {
        $scope.hideForm = true;
        $scope.showLoader = true;
        $http({
          method: 'POST',
          url: '/form/to-database',
          data: $.param($scope.signup), // pass in data as strings
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          } // set the headers so angular passing info as form data (not request payload)
        })
          .success(function (data) {
            $scope.showLoader = false;
            if (data.status === 'success') {
              // Tie lead id and email to clicky analytics data
              clicky_custom.visitor = {
                lead_id: data.id,
                email: data.email,
                signup: '1'
              };

              clicky_custom.goal = {
                name: 'Quick Signup'
              };

              // Log action in clicky
              clicky.goal('2672'); // Logs a "quick-signup" goal hit in clicky
              clicky.log('/quick-signup/thank-you'); // Logs a fake page hit in clicky

              // Fire off a Facebook Pixel Code (Lead)
              fbq('track', 'Lead');

              // Fire off a Google Analytics goal hit
              _gaq.push(['_trackPageview', '/ajax-goals/quick-signup-contact-us']);

              $scope.message = data.message;
              $scope.showMessage = true;
            } else {
              $scope.message = data.message;
              $scope.showMessage = true;
            }
          });
      }

      $scope.communities = CommunitiesListDataService.communities;

      $timeout(function () {
        // exclude The Arbors at Lakeline
        $scope.communities = _.filter($scope.communities, function (community) {
          return community.id !== 9;
        });
      }, 3000);

      $scope.scrollToCommunityContacts = function () {
        $('html, body').animate({
          scrollTop: $('#community-contacts').offset().top - 206
        });
      };
    }
  ]);
})();
