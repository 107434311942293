(function() {

  'use strict';
  /* global $, angular */

  angular.module("Milestone").directive('staggerIn', function() {
    //for ng-repeat
    return function(scope, elem, attrs) {
      elem.css('opacity', 0);
      if (scope.$last) {
        $('.grid').on('layoutComplete', function(event, items) {
          console.log("this: " + items.length);
          $('.grid-item').css('opacity', 1).hide().velocity('fadeIn', {
            stagger: 300
          });
        });
      }
    };
  });

})();
