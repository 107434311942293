(function() {

  'use strict';
  /* global $, angular */

  angular.module("Milestone").directive('dropdownStayOpen', function() {
    return function(scope, elem, attrs) {
      elem.bind("click",function(e){
          e.stopPropagation();
      });

      $(elem).find(".form-group select").bind("change",function(){

        var count = 0;
        $(elem).find(".form-group select").each(function(){
          if($(this).val() > 0){
            count++;
          }
        });

        if(count > 1){
          $(elem).parent().removeClass('open');
        }

      });
    };
  });

})();
