(function() {

  'use strict';
  /* global angular */

  angular.module("Milestone").directive('autoHeight', ['device', function(device) {
    return function(scope, elem, attrs) {

    	$(window).bind('load resize', function(){
        if($(window).height() > attrs.maxHeight){
          elem.height(attrs.maxHeight);
        }else{
          elem.height($(window).height() * attrs.minHeight);
        }
      })

    };
  }]);

})();
