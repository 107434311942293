angular.module('Milestone.Forms')

/* global LassoCRM, clicky, clicky_custom, _gaq */
  .directive('accessibleForm', function () {
    'use strict';
    return {
      restrict: 'A',
      link: function (scope, elem) {
        // set up event handler on the form element
        elem.on('submit', function () {
          // find the first invalid element
          var firstInvalid = elem[0].querySelector('.ng-invalid');

          // if we find one, set focus
          if (firstInvalid) {
            firstInvalid.focus();
          }
        });
      }
    };
  })

  .controller('QuickSignupFormCtrl', ['$scope', '$http', '$window', 'RegionsDataService', 'UserDataService',
    function ($scope, $http, $window, RegionsDataService, UserDataService) {
      'use strict';
      $scope.regions = [];
      RegionsDataService.regions
        .then(function(regions){
          $scope.regions = _.filter(regions, function (region) {
            return region.communities.length > 0;
          });
        });

      $scope.signup = {};
      UserDataService.getUser().then(function (user) {
        $scope.signup = angular.copy(user);
        $scope.signup.opt_in = 1;
        $scope.signup.origin = window.location.href;
        $scope.signup.type = 'signUp';
        $scope.signup.communities = [];
        $scope.signup.form_id = 177545;
        $scope.signup.guid = window.getLassoGuid();
      });

      $scope.hideForm = false;
      $scope.showMessage = false;
      $scope.showLoader = false;
      $scope.triedSubmit = false;

      $scope.noCommunities = function () {
        _.each($scope.signup.communities, function (v, k) {
          if (!v) {
            delete $scope.signup.communities[k];
          }
        });
        if (Object.keys($scope.signup.communities).length === 0) {
          return true;
        }
        return false;
      };

      $scope.checkFormValidation = function (form) {
        $scope.triedSubmit = true;

        if (!form.$valid) {
          return;
        }

        if ($scope.noCommunities()) {
          return;
        }
        processQuickSignUpForm();
      };

      function processQuickSignUpForm () {
        $scope.hideForm = true;
        $scope.showLoader = true;
        $http({
          method: 'POST',
          url: '/form/to-database',
          data: $.param($scope.signup), // pass in data as strings
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          } // set the headers so angular passing info as form data (not request payload)
        })
          .success(function (data) {
            $scope.showLoader = false;
            if (data.status === 'success') {
              // Tie lead id and email to clicky analytics data
              clicky_custom.visitor = {
                lead_id: data.id,
                email: data.email,
                signup: '1'
              };

              clicky_custom.goal = {
                name: 'Quick Signup'
              };

              // Log action in clicky
              clicky.goal('2672'); // Logs a "quick-signup" goal hit in clicky
              clicky.log('/quick-signup/thank-you'); // Logs a fake page hit in clicky

              // Fire off a Facebook Pixel Code (Lead)
              fbq('track', 'Lead');

              // Fire off a Google Analytics goal hit
              _gaq.push(['_trackPageview', '/ajax-goals/quick-signup-footer']);

              if (typeof googleConversionId !== 'undefined' && typeof googleConversionLabel !== 'undefined' && googleConversionId && googleConversionLabel) {
                $window.google_trackConversion({
                  google_conversion_id: googleConversionId,
                  google_conversion_language: 'en',
                  google_conversion_format: '3',
                  google_conversion_color: 'ffffff',
                  google_conversion_label: googleConversionLabel,
                  google_remarketing_only: false
                });
              }

              $scope.message = data.message;
              $scope.showMessage = true;
            } else {
              $scope.message = data.message;
              $scope.showMessage = true;
            }
          });
      }
    }
  ])

;
