(function() {

  'use strict';
  /* global angular */

  angular.module('Milestone').controller('SubNavController', ['$scope', '$location',
    function($scope, $location) {
      
      $scope.isActive = function(path) {
        var loc = $location.absUrl().split('/');

        if (loc[loc.length - 1] === path) {
          return true;
        } else {
          return false;
        }
      };

    }
  ]);
})();
