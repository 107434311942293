(function () {
  'use strict';
  /* global $, angular, clicky, clicky_custom, _gaq */

  angular.module('Milestone').controller('realtorsCtrl', ['$scope', '$http', 'UserDataService',
    function ($scope, $http, UserDataService) {
      UserDataService.getUser().then(function (user) {
        $scope.signup = angular.copy(user);
        $scope.signup.origin = window.location.href;
        $scope.signup.type = 'realtors';
        $scope.signup.form_id = 153759;
        $scope.signup.guid = window.getLassoGuid();
      });

      $scope.signup = {};

      $scope.hideForm = false;
      $scope.showMessage = false;
      $scope.showLoader = false;
      $scope.triedSubmit = false;

      $scope.checkFormValidation = function (form) {
        $scope.triedSubmit = true;

        if (!form.$valid) {
          return;
        }

        processRealtorsForm();
      };

      function processRealtorsForm () {
        $scope.hideForm = true;
        $scope.showLoader = true;
        $http({
          method: 'POST',
          url: '/form/to-realtors-table',
          data: $.param($scope.signup), // pass in data as strings
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          } // set the headers so angular passing info as form data (not request payload)
        })
          .success(function (data) {
            $scope.showLoader = false;
            if (data.status === 'success') {
              // Tie lead id and email to clicky analytics data
              clicky_custom.visitor = {
                lead_id: data.id,
                email: data.email,
                signup: '1'
              };

              clicky_custom.goal = {
                name: 'Realtors'
              };

              // Log action in clicky
              clicky.goal('13342'); // Logs a "quick-signup" goal hit in clicky
              clicky.log('/realtors/thank-you'); // Logs a fake page hit in clicky

              // Fire off a Facebook Pixel Code (Lead)
              fbq('track', 'Lead');

              // Fire off a Google Analytics goal hit
              _gaq.push(['_trackPageview', '/ajax-goals/quick-signup-realtors']);

              $scope.realtorId = data.id
              $scope.message = data.message;
              $scope.showMessage = true;
            } else {
              $scope.message = data.message;
              $scope.showMessage = true;
            }
          });
      }

    }
  ]);
})();
