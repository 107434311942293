/* global assert */
angular.module("Milestone.DataServices")

    .service("UserDataService", ["$http", function($http) {
        'use strict';

        var data = {
            promise: null
        };

        var getUser = function() {
            if (data.promise === null) {
                data.promise = $http.get('/user-data', {
                    cache: true
                }).then(userDataServiceResultWash);
            }

            return data.promise;
        };

        return {
            getUser: getUser
        };
    }])

;

var userDataServiceResultWash = function(result) {
    'use strict';
    if (result.data.user === undefined) {
        return {};
    }

    if (result.data.user.length < 1) {
        result.data.user = {};
    }
    return result.data.user;
};

// Testing
assert(_.isEqual({first_name: 'TestUser'}, userDataServiceResultWash({data: { user: { first_name: 'TestUser' }}})), 'User not returned correctly with a good user from API');

assert(_.isEmpty(userDataServiceResultWash({data: { user: [] }})), 'User not returned correctly when no user found in database');
assert(_.isEmpty(userDataServiceResultWash({data: { user: {} }})), 'User not returned correctly when empty user object supplied');
assert(_.isEmpty(userDataServiceResultWash({data: {} })), 'User not returned correctly when no user object in data payload');

assert(_.isObject(userDataServiceResultWash({data: { user: [] }})), 'User not returned correctly when no user found in database');
assert(_.isObject(userDataServiceResultWash({data: { user: {} }})), 'User not returned correctly when empty user object supplied');
assert(_.isObject(userDataServiceResultWash({data: {} })), 'User not returned correctly when no user object in data payload');
