/*jslint white: true, nomen: true */
/*global angular, googleApiKey */
(function commonServicesModuleInit() {

  /*
    In this module, we can store configuration and other services
    that will be shared by SmartEdit and Milestone.
  */
  'use strict';

  /* global angular, googleApiKey */

  angular
    .module("commonServices", ['uiGmapgoogle-maps']);

  angular
    .module("commonServices")
    .config(['uiGmapGoogleMapApiProvider', function(uiGmapGoogleMapApiProvider) {
      uiGmapGoogleMapApiProvider.configure({
        key: googleApiKey,
        v: '3',
        libraries: 'places'
      });
    }]);

}());
