(function() {
  'use strict';

  angular
    .module("Milestone.mySavedHomes")

    .directive('toggleResidence', ['$http', '$modal',
      function($http, $modal) {
        return {
          link: function(scope, elem, attrs) {
            elem.on('click', function(e) {
              e.stopPropagation();

              var action = attrs.action,
                residence_id = attrs.residenceId,
                logged_in = $('#user_logged_in_status').attr('data-logged_in'),
                modal_status = $('#favorites_modal_status').attr('data-favorites_modal_status'),
                hide_parent = elem.attr('data-hide-parent');

              $http.get('/my-saved-homes/favorite-residence', {
                params: {
                  action: action,
                  residence_id: residence_id
                }
              })
                .then(function successCallback(response) {
                  if (response.data.result === 'success') {
                    if (response.data.action === 'add') {
                      attrs.$set('action', 'remove');
                      elem.find('div').html('Remove from Favorites');
                    }
                    if (response.data.action === 'remove') {
                      attrs.$set('action', 'add');
                      elem.find('div').html('Save to Favorites');

                      if (hide_parent) {
                        elem.parent().parent().remove();
                      }
                    }
                    elem.toggleClass('saved');
                  }

                  if (!logged_in && modal_status !== 'hide' && response.data.action === 'add') {
                    $modal.open({
                      templateUrl: 'favoriteNotLoggedIn.html',
                      controller: 'favoriteNotLoggedInModalCtrl',
                      windowClass: 'modal-favorite-not-logged-in',
                      animation: true,
                      scope: scope
                    });

                    $http.get('/my-saved-homes/set-favorite-modal-state/hide')
                      .then(function successCallback(response) {
                        $('#favorites_modal_status').attr('data-favorites_modal_status', response.data);
                      }, function errorCallback(response) {});
                  }
                }, function errorCallback(response) {});

            });
          }
        };
      }
    ])
  ;
})();

