(function() {

  'use strict';
  /* global $, angular */

  angular.module("Milestone").controller("HomepageCtrl", ["$scope", "$rootScope", "scrollMagic",
    function($scope, $rootScope, scrollMagic) {

      var flip = false;

      $scope.displayWhereWeBuild = function(inview, inviewpart, event) {
        if (inviewpart) {
          $(".where-we-build").addClass("fade-in");
        }
      };

      $scope.displayRegion = function(inview, inviewpart, event) {
        if (inviewpart) {
          // $height = $(".regions").parent().height();
          // console.log("parent height " + $height);
          $(".regions").addClass("fade-in");
          $(".map").addClass("fade-in");
        }
      };

      $scope.displayWeb = function(inview, inviewpart, event) {
        if (inviewpart) {
          $(".why-choose").addClass("fade-in");
        }
      };

      $scope.displayCommunity = function(inview, inviewpart, event) {
        if (inview) {
          $(".new-communities").addClass("fade-in");
          $(".new-communities").addClass("slide-up");
          var imgHeight = $(".why-choose-right").height();
          $("img.left-img-half").css("height", imgHeight);
        }
      };

      $scope.displayLeftImage = function(inview, inviewpart, event) {
        if (inviewpart) {
          var $leftHalf = $(".why-choose .left-half");
          $leftHalf.addClass('fade-in');
        }
      };

      $scope.displayChooseText = function(inview, inviewpart, event) {
        if (inviewpart) {
          $(".why-choose-right").addClass("fade-in");
          $(".why-choose-text").addClass("slide-up");
        }
      };

      $scope.displayAccolades = function(inview, inviewpart, event) {
        if (inviewpart) {
          $(".accolades-text").addClass("slide-up");
          if (!flip) {
            $(".accolades .right-col img").velocity('transition.flipXIn', {
              delay: 800,
              stagger: 500
            });
            flip = true;
          }
        }
      };


      // TODO: Anything involving $(window) or $().find() is a good candidate for
      //       moving to a Directive. -wo
      
      $scope.hideCarousel = function(inview, inviewpart, event) {
        if(!$rootScope.is_phone){
          /*use cardstack.three to hide fixed carousel on scroll to offset safari bug*/
          scrollMagic.scrollTrigger(".cardStack.three","#homepageCarousel","hidden");
        }      
      }
    }
  ])
  ;

})();