(function() {

  'use strict';
  /* global angular */

  angular.module("Milestone").controller("MySavedHomesLoginCtrl", ["$scope", "$http", "$window",
    function($scope, $http, $window) {

      $scope.formData = {
      };
      $scope.hideForm = false;
      $scope.showMessage = false;

      $scope.processLoginForm = function() {
        $scope.showMessage = false;
        $http({
          method: 'POST',
          url: '/my-saved-homes/login',
          data: $.param($scope.formData), // pass in data as strings
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          } // set the headers so angular passing info as form data (not request payload)
        })
          .success(function(data) {
            if (data.status == 'success') {
              $scope.status = data.status;
              $scope.message = data.message;
              $scope.showMessage = true;
              $window.location.href = '/my-saved-homes/my-profile';
            } else {
              $scope.status = data.status;
              $scope.message = data.message;
              $scope.showMessage = true;
            }
          });
      };
    }
  ]);

})();
