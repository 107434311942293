(function() {

  'use strict';
  /* global angular */

  angular
    .module("Milestone.microsite")

    .service('LocalAmenitiesService', ['$sce', function($sce) {

      return {
        data: defaultLocalAmenities
      };

    }])
  ;

  var defaultLocalAmenities = [
    {
      name: 'Recreation',
      icon: 'icon-recreation',
      pin_icon: '/images/site/icons/pin-recreation.png',
      pins: [],
      types: ['aquarium', 'art_gallery', 'campground', 'museum', 'park', 'zoo']
    },
    {
      name: 'Eateries',
      icon: 'icon-restaurant',
      pin_icon: '/images/site/icons/pin-eateries.png',
      pins: [],
      types: ['bakery', 'bar', 'cafe', 'food', 'meal_delivery', 'meal_takeaway', 'restaurant']
    },
    {
      name: 'Grocery',
      icon: 'icon-grocery',
      pin_icon: '/images/site/icons/pin-grocery.png',
      types: ['grocery_or_supermarket'],
      pins: []
    },
    {
      name: 'Shopping',
      icon: 'icon-shopping',
      pin_icon: '/images/site/icons/pin-shopping.png',
      types: ['beauty_salon', 'bicycle_store', 'book_store', 'clothing_store', 'department_store', 'electronics_store', 'furniture_store', 'hair_care', 'hardware_store', 'home_goods_store', 'jewelry_store', 'pet_store', 'shoe_store', 'shopping_mall'],
      pins: []
    },
    {
      name: 'Entertainment',
      icon: 'icon-entertainment',
      pin_icon: '/images/site/icons/pin-entertainment.png',
      types: ['amusement_park', 'bowling_alley', 'casino', 'movie_rental', 'movie_theater', 'night_club', 'spa', 'stadium'],
      pins: []
    },
    {
      name: 'Hospitals',
      iconHTML: '<span class="icon-hospitals"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>',
      pin_icon: '/images/site/icons/pin-hospitals.png',
      pins: [],
      types: ['hospital']
    }
  ];

})();