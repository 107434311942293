;(function() {
  'use strict';
  /* global angular */

  angular
    .module("Milestone.microsite")

    .controller("subnavCtrl", ['$scope', 'scrollMagic', function($scope, scrollMagic) {

      scrollMagic.affix('.affix-trigger','#microsite-secondary','fixed');
      scrollMagic.affix('.section-wrap','.navbar-fixed-top, #microsite-secondary','hide-main-nav');

    }])
  ;

})();
