(function() {

  'use strict';
  /* global angular, _RegionId */

  angular
    .module("Milestone")

    .controller("RegionalCtrl", ["$scope", "RegionsDataService", "RegionDataService", "CommunitiesListDataService", "UserDataService", "$http", "$filter", "scrollMagic",
      function($scope, RegionsDataService, RegionDataService, CommunitiesListDataService, UserDataService, $http, $filter, scrollMagic) {

        $scope.region = null;
        var region = RegionDataService.region(_RegionId)
          .then(function(region){
            $scope.region = region;
            return region;
          });

        var getRange = function(column) {
          var min = Math.min.apply(Math, $scope.region.communities.map(function(c){ return c[column]; }).filter(function(c){ return (c > 0) ? c : false; }));
          var max = Math.max.apply(Math, $scope.region.communities.map(function(c){ return c[column]; }).filter(function(c){ return (c > 0) ? c : false; }));
          return {min: min, max: max};
        };

        $scope.ranges = {};
        region
          .then(function(region){

            $scope.ranges = {
              bed_min:   getRange("bed_low"),
              bed_max:   getRange("bed_high"),
              bath_min:  getRange("bath_low"),
              bath_max:  getRange("bath_high"),
              price_min: getRange("price"),
              price_max: getRange("price_high"),
              sqft_min:  getRange("sqft_low"),
              sqft_max:  getRange("sqft_high")
            };

          });

        $scope.regions = [];
        RegionsDataService.regions
          .then(function(regions){
            $scope.regions = regions;
          });

        $scope.naturalSort = function(community) {
          return community.name.replace(/^The /, '');
        };

        $scope.filters = {};

        $scope.filterHomes = function(community) {

          var result = true;

          var filters = [
            ($scope.filters.bed_min   && parseFloat($scope.filters.bed_min)   > parseFloat(community.bed_low)),
            ($scope.filters.bed_max   && parseFloat($scope.filters.bed_max)   < parseFloat(community.bed_high)),
            ($scope.filters.bath_min  && parseFloat($scope.filters.bath_min)  > parseFloat(community.bath_low)),
            ($scope.filters.bath_max  && parseFloat($scope.filters.bath_max)  < parseFloat(community.bath_high)),
            ($scope.filters.price_min && (community.price_status != 'Pricing Available' || parseFloat($scope.filters.price_min) > parseFloat(community.price))),
            ($scope.filters.price_max && (community.price_status != 'Pricing Available' || parseFloat($scope.filters.price_max) < parseFloat(community.price_high))),
            ($scope.filters.sqft_min  && parseFloat($scope.filters.sqft_min)  > parseFloat(community.sqft_low)),
            ($scope.filters.sqft_max  && parseFloat($scope.filters.sqft_max)  < parseFloat(community.sqft_high))
          ];

          // One false filter trips the circuit
          var i = 0;
          while (result === true && (i <= filters.length - 1)) {

            if (filters[i]) {
              result = false;
            }

            i++;
          }

          return result;
        };

        $scope.selectRange = function(min, max, step) {
          var res = [];

          var startRange = min - (min % step); // start with one notch below the min
          var endRange = (max % step === 0) ? max : (max + step) - (max % step); // end with one notch above the max or with the max

          for(var i = startRange; i <= endRange; i = i + step) {
            res.push(i);
          }

          return res;
        };
        
        $scope.rangeLabel = function(fallback, min, max, filter, p1, p2) {

          var min_string = min;
          var max_string = max;

          if (filter !== undefined) {
            min_string = $filter(filter)(min, p1, p2);
            max_string = $filter(filter)(max, p1, p2);
          }

          if (min > 0 && max > 0) {
            return min_string + " - " + max_string;
          }

          if (min > 0) {
            return min_string + "+";
          }

          if (max > 0) {
            return "-" + max_string;
          }

          return fallback;
        };

        scrollMagic.affix(".affix-trigger",".search-box","affix");



        $scope.signup = {};
        UserDataService.getUser().then(function (user) {
          $scope.signup = angular.copy(user);
          $scope.signup.origin = window.location.href;
          $scope.signup.type = 'signUpMicrosite';
          $scope.signup.communities = [];
          //$scope.signup.communities[0] = $scope.community.id;
          $scope.signup.form_id = 177545;
          $scope.signup.guid = window.getLassoGuid();
        });

        $scope.hideForm = false;
        $scope.showMessage = false;
        $scope.showLoader = false;
        $scope.triedSignupSubmit = false;

        $scope.checkSignupFormValidation = function (form) {
          $scope.triedSignupSubmit = true;

          if (!form.$valid) {
            return;
          }

          processQuickSignUpForm();
        };

        var processQuickSignUpForm = function () {
          $scope.hideForm = true;
          $scope.showLoader = true;
          $http({
            method: 'POST',
            url: '/form/to-database',
            data: $.param($scope.signup), // pass in data as strings
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded'
            } // set the headers so angular passing info as form data (not request payload)
          }).success(function (data) {
            $scope.showLoader = false;
            if (data.status === 'success') {
              // Tie lead id and email to clicky analytics data
              clicky_custom.visitor = {
                lead_id: data.id,
                email: data.email,
                signup: '1'
              };

              clicky_custom.goal = {
                name: 'Quick Signup'
              };

              // Log action in clicky
              clicky.goal('2672'); // Logs a "quick-signup" goal hit in clicky
              clicky.log('/quick-signup/thank-you'); // Logs a fake page hit in clicky

              // Fire off a Facebook Pixel Code (Lead)
              fbq('track', 'Lead');

              // Fire off a Google Analytics goal hit
              _gaq.push(['_trackPageview', '/ajax-goals/quick-signup-microsite']);

              if (typeof googleConversionId !== 'undefined' && typeof googleConversionLabel !== 'undefined' && googleConversionId && googleConversionLabel) {
                $window.google_trackConversion({
                  google_conversion_id: googleConversionId,
                  google_conversion_language: 'en',
                  google_conversion_format: '3',
                  google_conversion_color: 'ffffff',
                  google_conversion_label: googleConversionLabel,
                  google_remarketing_only: false
                });
              }

              $scope.message = data.message;
              $scope.showMessage = true;
            } else {
              $scope.message = data.message;
              $scope.showMessage = true;
              $scope.hideForm = false;
            }
          });
        };
      }
    ])

    .directive("rangeSelect", function(){

    })

  ;

})();
