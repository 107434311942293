(function() {

  'use strict';
  /* global angular, _HomeDataCache */

  angular
    .module("Milestone")

    .service('HomeDataCache', function() {

      var data = {
        home: null
      };

      if (typeof _HomeDataCache !== 'undefined') {
        data.home = _HomeDataCache.home;
      }

      return {
        home: data.home
      };

    })

  ;

})();
