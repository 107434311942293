(function() {

  'use strict';
  /* global $, angular */

  angular.module("Milestone").controller("careersCtrl", ["$scope",
    function($scope) {

      $scope.displayCareers = function(inview, inviewpart, event) {
        if (inview) {
          $('.fader').removeClass('fader');
        }
      };

    }
  ]);

})();
