(function() {
  'use strict';
  /* global $, angular */

  angular
    .module("Milestone")
    .controller("DesignCtrl", ["$scope", "$rootScope", "scrollMagic",
      function($scope, $rootScope, scrollMagic) {

        $scope.displaySectionOne = function(inview, inviewpart, event) {
          if (inview && !$('.cardStack.one').hasClass('is_seen')) {
            $('.cardStack.one').addClass('is_seen').find('.fader').hide().velocity('fadeIn', {
              stagger: 350
            });
          }
        };

        $scope.displaySectionTwo = function(inview, inviewpart, event) {
          if (inview && !$('.cardStack.two').hasClass('is_seen')) {
            $('.cardStack.two').addClass('is_seen').find('.fader').hide().velocity('fadeIn', {
              stagger: 350
            });
          }
        };

        $scope.displaySectionThree = function(inview, inviewpart, event) {
          if (inview && !$('.cardStack.three').hasClass('is_seen')) {
            $('.cardStack.three').addClass('is_seen').find('.fader').hide().velocity('fadeIn', {
              stagger: 350
            });
          }
        };
      }
    ])
  ;
})();
