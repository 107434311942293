(function() {

    'use strict';
    /* global $, angular */

    angular.module('Milestone')
        .directive('msAccessibleForm', ["$window", function ($window) {
            return {
                restrict: 'A',
                link: function (scope, elem) {

                    // set up event handler on the form element
                    elem.on('submit', function () {

                        // find the first invalid element
                        var firstInvalid = elem[0].querySelector('.ng-invalid');

                        // if we find one, set focus
                        if (firstInvalid) {
                            firstInvalid.focus();
                            var pos = findPos(firstInvalid);
                            $window.scrollTo(0,pos[1] - 300);
                        }
                    });

                    function findPos(obj) {
                        var curtop = 0;
                        var curleft = 0;
                        if (obj.offsetParent) {
                            do {
                                curleft += obj.offsetLeft;
                                curtop += obj.offsetTop;
                            } while (obj = obj.offsetParent);

                            return [curleft,curtop];
                        }
                    }
                }
            };
        }])
})();