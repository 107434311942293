(function() {

  'use strict';
  /* global $, angular */

  angular
    .module("Milestone")
    .directive('masonryCarousel', function() {
      return function(scope, elem, attrs) {

        $("#masonry-carousel .item").each(function() {

          var $gridItem = $(this).find(".grid-item");

          //columns
          var col = 3;

          //group by 3 columns
          var chunks = $gridItem.length / col;

          //slice grids based on bootstrap
          for (var i = 0; i < $gridItem.length; i += chunks) {
            $gridItem.slice(i, i + chunks).wrapAll("<div class='col-sm-" + 12 / col + "'></div>");
          }

          //reverse columns
          $(this).append($(this).find('div[class^="col-"]').get().reverse());
        });

      };
    });

})();
