(function() {

  'use strict';
  /* global angular */

  angular
    .module("Milestone.microsite")

    .service('HomeFeaturesService', ['MicrositeDataCache', '$q', function(MicrositeDataCache, $q) {

      var featuresPromise = $q(function(resolve, reject) {
        var features = [],
            featureGroups = [];

        if (MicrositeDataCache !== undefined) {
          angular.forEach(MicrositeDataCache.community.feature_groups, function(fg) {
            featureGroups.push(fg);
          });
          angular.forEach(MicrositeDataCache.community.features, function(f) {
            features.push(f);
          });
        }

        resolve({
          featureGroups: featureGroups,
          features: features,
        });
      });

      return {
        data: featuresPromise
      };
    }])
  ;

})();