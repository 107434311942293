(function() {

  'use strict';
  /* global $, angular */

  angular
  	.module("Milestone.Maps")
  	.directive('lotmapPosition', [function() {
    	return function(scope, elem) {

    		//get parent diemnsions
	    	var h = $(".site-plan-pins").height() * 0.5;
	    	var w = $(".site-plan-pins").width() * 0.35;

	    	$(elem).on('mouseenter touchstart', function(){
	    		console.log("lotmap");
	    		var position = $(elem).parent().position();

	    		//height
	    		if(position.top > h){
	    			togglePosition('top','bottom');
	    		}else{
	    			togglePosition('bottom','top');
	    		}

	    		//width
	    		if(position.left > w){
	    			togglePosition('left','right');
	    		}else{
	    			togglePosition('right','left');
	    		}

	    		function togglePosition(oldPos,newPos){
	    			$(elem).next().removeClass(oldPos).addClass(newPos);
	    		}
	    	})
	    }
  }]);

})();
