(function() {

  'use strict';
  /* global angular */

  angular.module("Milestone").controller("MySavedHomesMyProfileCtrl", ["$scope", "$http", "$window", "StatesListDataService",
    function($scope, $http, $window, StatesListDataService) {
      
      $scope.formNewData = {
      };

      $http({
        method: 'GET',
        url: '/my-saved-homes/my-profile',
        // data    : $.param($scope.formData),  // pass in data as strings
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        } // set the headers so angular passing info as form data (not request payload)
      })
        .success(function(data) {
          if (data.status == 'success') {
            $scope.formData = data.user;
          } else {
          }
        });

      $scope.processMyProfileForm = function() {
        $scope.showMessage = false;
        if ($scope.formNewData.password === undefined && $scope.formNewData.password_repeat === undefined) {
          // do nothing
        } else if ($scope.formNewData.password && $scope.formNewData.password_repeat && $scope.formNewData.password == $scope.formNewData.password_repeat) {
          $scope.formData.password = $scope.formNewData.password;
        } else {
          $scope.status = 'failure';
          $scope.message = "Passwords do not match.";
          $scope.showMessage = true;
          return false;
        }

        $http({
          method: 'POST',
          url: '/my-saved-homes/my-profile',
          data: $.param($scope.formData), // pass in data as strings
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          } // set the headers so angular passing info as form data (not request payload)
        })
          .success(function(data) {
            if (data.status == 'success') {
              $window.location.href = '/my-saved-homes/my-profile';
            /*
            $scope.message = data.message;
            $scope.hideForm = true;
            $scope.showMessage = true;
            */
            } else {
              $scope.message = data.message;
              $scope.showMessage = true;
            }
          });
      };

    }
  ]);

})();
