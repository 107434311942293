(function() {
  'use strict';
  /* global $, angular */
  angular
    .module("Milestone")
    .directive('scrollTo', ['device', function(device) {
      return function(scope, elem, attrs) {

        $(window).on('load resize', function(){        
          var clickType = 'click';

          if(device.touch()){
            console.log("is touch device");
            clickType = 'touchend';
          }

          elem.on(clickType, function(event) {
            event.preventDefault();

            var target = attrs.target;
            var offset = attrs.offsetDesktop;
            if(device.check() == 'phone'){
              offset = attrs.offsetMobile;
            }

            $('html, body').stop().animate({
              scrollTop: $(target).offset().top - offset
            }, 800, 'easeOutQuart');

          });
        })

      };
    }]);
})();