(function() {

  'use strict';
  /* global $, angular */

  angular.module("Milestone.microsite").directive('verticalcenter', ['device', function(device) {
    return function(scope, element) {

      $(document).ready(calculate);
      $(window).resize(calculate);

      function calculate(){

        var persistentFeaturesHeight = $('.persistent-features').height() * 0.5;
        var topPosition = 0;

        if(device.check() != "phone"){
          topPosition = $(window).height() / 2 -  persistentFeaturesHeight;
        }

        $(element).css('top', topPosition);

      }

    };
  }]);

})();
