(function() {

  'use strict';
  /* global angular, _MicrositeDataCache */

  angular
    .module("Milestone.DataServices")

    .service('MicrositeDataCache', function() {

      var data = null,
        lots = null;

      if (typeof _MicrositeDataCache !== 'undefined') {
        data = _MicrositeDataCache.community;
        lots = _MicrositeDataCache.lots;
      }

      return {
        community: data,
        lots: lots
      };

    })
  ;

})();