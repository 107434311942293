(function() {

  'use strict';
  /* global angular, $ */

  /*
  this just adds functionality but dropdowns still need to be styled respectively;
  */


  angular.module("Milestone")
  .directive('mobileTabToDropdown', ['device','$timeout', function(device, $timeout) {
    return function(scope, elem, attrs) {
		    var $dropdown = $(elem).parent();

	    	elem.bind('click', function(){
	    		console.log("clicked");
	    		if(device.check() == "phone"){
		    		/*toggle dropdown menu and set text*/
		    		$dropdown.toggleClass('active');
		    		scope.setText(elem.text());

		    		/*toggle active states for list items*/
					$(elem).addClass('active').siblings().removeClass('active');
	    		}
	    	})

    		if(scope.$last){	
		    	//assign listener
		    	$dropdown.prev().bind('click', function(){
		    		if($dropdown.hasClass('active')){
		    			$dropdown.toggleClass('active');
		    		}else{	    	
		    			var $activeDropdown = $('.mobile-tab-to-dropdown.active');
			    		$activeDropdown.removeClass('active').next().removeClass('active');
			    		$dropdown.toggleClass('active').prev().addClass('active');
		    		}
		    	})

		    	$timeout(function(){
		    		var title = $dropdown.find('.active').text();
	    			scope.setText(title);
		    	},0)
    		}

	    	scope.setText = function(copy){
	    		$dropdown.prev().find('span').text(copy);
	    	}

    };
  }]);

})();