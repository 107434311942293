/*jslint white: true, nomen: true */
/*global angular */
(function() {
  'use strict';
  /* global angular */

  angular
    .module("Milestone.HomePage")

    .controller('whereWeBuildCtrl', ['$scope', 'RegionDataPromiseService',
      function($scope, RegionDataPromiseService) {

        $scope.parent = $scope;

        $scope.regions = [];

        $scope.selectedRegions = [];

        RegionDataPromiseService.promise
          .then(function(result) {
            $scope.regions = _.filter(result.data.regions, function (region) {
              return region.communities.length > 0;
            });
          });

        $scope.toggleSelectRegion = function(region) {
          $scope.selectedRegions = [region];
          $scope.parent.$broadcast("selectedRegion", region.id);
        };
      }
    ])

    .controller('whereWeBuildRegionMapCtrl', ['$scope', '$q', '$timeout', 'uiGmapPromises', 'RegionDataPromiseService',
      function($scope, $q, $timeout, uiGmapPromises, RegionDataPromiseService) {

        var austin = {
          latitude: 30.3298767,
          longitude: -97.6948925
        };

        uiGmapPromises.GoogleMapsApiReady()
          .then(function init(GoogleMaps) {

            $scope.map = {
              center: austin,
              control: {},
              markers: [],
              zoom: 9,
              options: {
                streetViewControl: false,
                draggable: true,
                scrollwheel: true,
                mapTypeControl: false,
                panControl: true,
                panControlOptions: {
                  position: GoogleMaps.ControlPosition.RIGHT_CENTER
                },
                zoomControl: true,
                zoomControlOptions: {
                  position: GoogleMaps.ControlPosition.RIGHT_CENTER
                },
              },
              markersEvents: {
                click: function(marker, eventName, model, args) {
                  $scope.community = model.community;
                  $scope.dialog = angular.element("#info-window-community")[0];
                  $timeout(function () {
                    $scope.dialog.show();
                  }, 150);

                  $scope.map.window.options.pixelOffset = new GoogleMaps.Size(0, -50);
                  $scope.map.window.coords = marker.center;
                  hideWindows();
                  $scope.map.window.marker = marker;
                  $scope.map.window.model = model;
                  $scope.map.window.show = true;
                }
              },
              window: {
                marker: {},
                show: false,
                closeClick: function() {
                  this.show = false;
                  this.model = {};
                },
                options: {
                  maxWidth: 800
                }
              }
            };

          });

        $scope.closeDialog = function() {
          $scope.dialog.close();
        }

        var markerGroups = {};

        var addMarkers = function() {
          angular.forEach($scope.regions, function(region) {

            var markerGroup = [];

            angular.forEach(region.communities, function(community) {

              var marker = {
                id: community.id,
                name: community.name,
                latitude: community.latitude,
                longitude: community.longitude,
                community: community,
                icon: '/images/site/blue-icon-pin.png',
                options: {
                  visible: true
                }
              };

              markerGroup.push(marker);

            });

            markerGroups[region.id] = markerGroup;
          });
        };

        var selectFirstRegion = function() {
          $scope.toggleSelectRegion($scope.regions[0]);
        };

        var hideWindows = function() {
          // hide any windows
          $scope.map.window.show = false;
          $scope.map.window.marker = null;
          $scope.map.window.model = null;
        };

        var fitBoundsWithCurrentMarkers = function(theMap, GoogleMaps) {

          hideWindows();

          GoogleMaps.event.trigger(theMap, 'resize');

          var newBounds = new GoogleMaps.LatLngBounds();

          //newBounds.extend(new GoogleMaps.LatLng(austin.latitude, austin.longitude));

          angular.forEach($scope.map.markers, function(marker) {
            newBounds.extend(new GoogleMaps.LatLng(marker.latitude, marker.longitude));
            newBounds.extend(new GoogleMaps.LatLng(0.005 + (1 * marker.latitude), 0.005 + (1 * marker.longitude)));
          });

          theMap.fitBounds(newBounds);

          if (theMap.getZoom() > 14) {
            theMap.setZoom(14);
          }
        };

        RegionDataPromiseService
          .promise
          .then(addMarkers)
          .then(uiGmapPromises.GoogleMapsApiReady)
          .then(function init(GoogleMaps) {
            uiGmapPromises.GoogleMapsIsAllReady()
              .then(function(theMap) {

                matchElemHeightPromise.promise
                  .then(function() {
                    GoogleMaps.event.trigger(theMap, 'resize');
                  })
                  .then(selectFirstRegion)
                  .then(function() {
                    return fitBoundsWithCurrentMarkers(theMap, GoogleMaps);
                  });

              });
          });


        var displayRegionMarkers = function(region_id) {
          $scope.map.markers = [];
          angular.forEach(markerGroups[region_id], function(marker) {
            $scope.map.markers.push(marker);
          });

          uiGmapPromises.GoogleMapsApiReady()
            .then(function init(GoogleMaps) {
              uiGmapPromises.GoogleMapsIsAllReady()
                .then(function(theMap) {
                  return fitBoundsWithCurrentMarkers(theMap, GoogleMaps);
                });
            });

        };

        var matchElemHeightPromise = $q.defer();
        $scope.$on("matchElemHeight", function() {
          matchElemHeightPromise.resolve(true);
        });

        $scope.parent.$on("selectedRegion", function(event, region_id) {
          displayRegionMarkers(region_id);
        });

      }
    ])

    .controller("whereWeBuildRegionSelectorCtrl", ['$scope', 'RegionDataPromiseService', '$timeout',
      function($scope, RegionDataPromiseService, $timeout) {}
    ])
  ;

})();
