angular
  .module("Milestone.microsite")

  .controller("GalleryPhotosCtrl", ["$scope", "$filter", 'CommunityService', 'msSlideShowDataService',
    function($scope, $filter, CommunityService, msSlideShowDataService) {

      'use strict';

      $scope.community = CommunityService.data;
      $scope.galleryPhotos = CommunityService.data.assets;

      $scope.galleryCategories = ['Featured', 'Exteriors', 'Interiors', 'Living Rooms', 'Kitchens', 'Dining Rooms', 'Bedrooms', 'Bathrooms'];
      $scope.selectedGalleryCategory = null;

      $scope.sortedGalleryImages = {};

      $scope.mobileTrigger = function(target){
        $(target).focus();
      }

      $scope.galleryFilter = $scope.galleryCategories[1];


      $scope.galleryHasImages = function(category) {
        return ($scope.sortedGalleryImages[category].length > 0);
      };

      $scope.selectGalleryCategory = function(category) {
        $scope.selectedGalleryCategory = category;
        msSlideShowDataService.gallery = $scope.sortedGalleryImages[category];
      };

      $scope.selectImage = function(image) {
        msSlideShowDataService.select(image);
        $scope.$broadcast('msSlideShow:showSlideShow');
      };

      // this fires upward to alert parent controllers that the gallery is ok
      $scope.okToShowGalleryImages = function() {
        $scope.$emit('galleryPhotosCtrl:showTheGallery');

        // trip switch
        $scope.okToShowGalleryImages = function() {};
      };

      // Return a copy of the assets that match the category.
      // This may not be a perfect way to get these; it could mess up if any
      // assets have metadata that includes category keywords, so if that is
      // a problem you can fix it here.
      $scope.filterAssetsByCategory = function(assets, category) {

        if (assets.length === undefined || assets.length < 1) {
          return [];
        }

        return $filter('filter')(angular.copy(assets), category);

      
      };

      $scope.setUpSortedGalleryImages = function() {

        $scope.sortedGalleryImages['All'] = $scope.community.assets;

        if ($scope.galleryHasImages('All')) {
          $scope.selectGalleryCategory('All');
          $scope.okToShowGalleryImages();
        }

        /*
        angular.forEach($scope.galleryCategories, function(category) {

          // Put all the Assets with this category into the sorted object
          $scope.sortedGalleryImages[category] = $scope.filterAssetsByCategory($scope.community.assets, category);

          // Pre-select the first category with the first one that has images
          if ($scope.selectedGalleryCategory === null) {
            if ($scope.galleryHasImages(category)) {
              $scope.selectGalleryCategory(category);

              // We found at least 1 gallery category with images, so let the
              // parent controller know that we can show the gallery.
              $scope.okToShowGalleryImages();
            }
          }
        });
        */
      };

      $scope.setUpSortedGalleryImages();
    }
  ]);
