(function() {

  'use strict';
  /* global $, angular */

  angular.module('Milestone.Forms')
    .directive('msDatepicker', function() {
      return {
        restrict: "E",
        scope: {
          formattedDate: "=ngModel",
          opened: "=",
          defaultPlaceholder: "=",
          name: "="
        },
        link: function($scope) {

        },
        templateUrl: 'ms-datepicker.html',
        controller: ["$scope", "$filter", function($scope, $filter) {

          // Format the date selection and make it available
          $scope.$watch("dt", function(value, oldValue) {
            if (value == oldValue) return;
            $scope.formattedDate = $filter('date')(value, 'yyyy-MM-dd');
          });

          // Prevent invalid selectinos
          var minDate = new Date();
          minDate.setDate(minDate.getDate()+1);
          minDate = $filter('date')(minDate, 'yyyy-MM-dd');

          var maxDate = new Date();
          maxDate.setYear(maxDate.getFullYear()+3);
          maxDate.setDate(maxDate.getDate()+1);
          maxDate = $filter('date')(maxDate, 'yyyy-MM-dd');

          $scope.dateOptions = {
            'show-weeks': false,
            'min-date': "'" + minDate + "'",
            'max-date': "'" + maxDate + "'"
          };

          $scope.open = function(event) {
            event.preventDefault();
            event.stopPropagation();
            $scope.opened = true;
          };

          $scope.clear = function() {
            $scope.ngModel = null;
          };

        }]
      };

    });

})();
