;(function() {
  'use strict';
  /* global angular */

  angular
    .module("Milestone.microsite")

    .controller("FeaturesCtrl", ['$scope', 'HomeFeaturesService', '$filter', function($scope, HomeFeaturesService, $filter) {

      HomeFeaturesService.data
        .then(function(data) {

          // order/sort active featureGroups and active features by sequence_position
          $scope.featureGroups = $filter('orderBy')($filter('filter')(data.featureGroups, { is_active: 1 }), 'sequence_position');
          $scope.features = $filter('orderBy')($filter('filter')(data.features, { is_active: 1 }), 'sequence_position');

          if ($scope.featureGroups.length)
          {
            $scope.selectedFeatureGroup = $scope.featureGroups[0];
            $scope.featureGroupId = $scope.selectedFeatureGroup.id;

            var features = $filter('filter')($scope.features, { feature_group_id: $scope.featureGroupId });
          }
          else
          {
            var features = $scope.features;
          }
          $scope.selectFeature(features[0]);

        })
      ;

      $scope.toggleTab = function(tabName, tabId){
        var elem = $(tabName.currentTarget);
        elem.addClass('active').siblings().removeClass('active');

        $scope.selectedFeatureGroup = $filter('filter')($scope.featureGroups, { id: tabId })[0];
        $scope.featureGroupId = tabId;

        var features = $filter('filter')($scope.features, { feature_group_id: tabId });
        $scope.selectFeature(features[0]);
      }
      
      $scope.selectFeature = function(feature) {
        $scope.selected = feature;
      };

    }])
  ;

})();