angular
  .module("common")

  .filter('range', ['$filter', function($filter) {
    'use strict';

    return function(param, fractionSize) {

      if (param === undefined || param === null) {
        return "TBD";
      }

      var input = {
        low: 0,
        high: 0
      };

      if (typeof param === 'object') {
        input.low = parseFloat(param.low);
        input.high = parseFloat(param.high);
      } else {
        input = {
          low: parseFloat(param),
          high: 0
        };
      }

      // Don't display whole numbers with fractional syntax
      // "1.0 - 2.5" should be "1 - 2.5"
      var lo_fraction = (input.low === Math.floor(input.low)) ? 0 : fractionSize;
      var hi_fraction = (input.high === Math.floor(input.high)) ? 0 : fractionSize;

      var lo = input.low || 0;
      var hi = input.high || 0;

      if (lo === 0 && hi === 0) {
        return "TBD";
      }

      if (hi === 0) {
        return $filter('number')(lo, lo_fraction);
      }

      if (lo === 0) {
        return $filter('number')(hi, hi_fraction);
      }

      if (hi > lo) {
        return $filter('number')(lo, lo_fraction) + ' - ' + $filter('number')(hi, hi_fraction);
      }

      return $filter('number')(lo, lo_fraction);
    };
  }])

  .directive('range', ['$filter', function($filter) {
    'use strict';
    
    return {
      link: function(scope, elem, attrs) {

        elem = $(elem);

        var content = elem.html().replace(/,/g, '');

        // split on a hyphen-minus character
        var split_content = content.split('-');

        // only works if we get ["CHUNK", "CHUNK"]
        if (split_content.length === 2) {
          var new_content = $filter('range')({
            'low': split_content[0],
            'high': split_content[1]
          }, attrs.decimals || 0);
          elem.html(new_content);
          return;
        }
      }

    };
  }])
;
