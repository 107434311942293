;(function() {
  'use strict';
  /* global angular */

  angular
    .module("Milestone.microsite")


    // this has to be a sub-controfller of FloorPlansTabsCtrl
    .controller("CommunitySitePlanCtrl", ['$scope', 'CommunitySitePlanService', '$location',
      function($scope, CommunitySitePlanService, $location) {

        // data shared with sub-controllers
        $scope.data = CommunitySitePlanService.data;

        var populateLotTemplateObject = function(lot) {
          var res = {
            elevation: chooseElevation(lot),
            price: displayValue(lot, 'price'),
            beds: displayValue(lot, 'beds'),
            baths: displayValue(lot, 'baths'),
            sqft: displayValue(lot, 'sqft')
          };

          return angular.extend(res, lot);
        };

        $scope.showFloorplan = function(seo_name) {
          $location.url('floorplans/' + seo_name);
          $scope.scrollToUrl();
        };


        var displayValue = function(lot, key) {
          if (lot.home === undefined || lot.home === null) {
            return lot[key];
          } else {
            if (lot.home[key] === undefined || lot.home[key] === null) {
              console.error("lot.home[" + key + "] was not found...");
            } else {
              return lot.home[key];
            }
          }
        };

        var chooseElevation = function(lot) {

          if (lot.home && lot.home.elevation && lot.home.elevation.img.medium !== undefined) {
            return lot.home.elevation.img.medium;
          }

          if (lot.home && lot.home.residence && lot.home.residence.elevations[0] !== undefined && lot.home.residence.elevations[0].img.medium !== undefined) {
            return lot.home.residence.elevations[0].img.medium;
          }

          // really really default
          return "/images/site/microsite/site-plan-popover-thumb.png";

        };

        $scope.lots = [];
        angular.forEach($scope.data.lots, function(lot) {
          $scope.lots.push(populateLotTemplateObject(lot));
        });


      }
    ])
  ;

})();
