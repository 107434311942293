(function() {

  "use strict";

  angular
    .module("Milestone")

    .controller("MySavedHomesSavedHomesCtrl", ["$scope", "$timeout",
      function($scope, $timeout) {

        $scope.regions = _SavedHomesDataCache.regions;
        $scope.communities = _.toArray(_SavedHomesDataCache.communities);

        if ($scope.regions.length > 0) {
          $scope.selectedCategoryId = $scope.regions[0].id;
          $scope.selectedCategoryName = $scope.regions[0].name;
        }
        
        $scope.selectCategory = function(region_id) {
          $scope.selectedCategoryId = region_id; 
        };

        $scope.savedHomesCategories = [
          {
            id: 1,
            name: 'North Austin & Cedar Park'
          },
          {
            id: 2,
            name: 'Downtown / South Austin'
          },
          {
            id: 3,
            name: 'Buda'
          },
          {
            id: 4,
            name: 'Hill Country'
          }
        ];
        $scope.savedHomes = [
          {
            communityName: 'Community Name',
            categoryId: 1,
            items: [

              {
                image: '../images/site/elevation.png',
                name: 'Dayton',
                link: '',
                beds: '3',
                baths: '2',
                sqft: '1,667',
                price: 'PRICE TBD'
              },
              {
                image: '../images/site/elevation.png',
                name: 'Dayton',
                link: '',
                beds: '3',
                baths: '2',
                sqft: '1,667',
                price: 'PRICE TBD'
              },
              {
                image: '../images/site/elevation.png',
                name: 'Dayton',
                link: '',
                beds: '3',
                baths: '2',
                sqft: '1,667',
                price: 'PRICE TBD'
              }
            ]
          },
          {
            communityName: 'Community Name',
            categoryId: 1,
            items: []
          },
          {
            communityName: 'Community Name',
            categoryId: 1,
            items: []
          },
          {
            communityName: 'Community Name',
            categoryId: 1,
            items: []
          },
          {
            communityName: 'Community Name',
            categoryId: 1,
            items: []
          },
          {
            communityName: 'Community Name',
            categoryId: 2,
            items: [
              {
                image: '../images/site/elevation.png',
                name: 'Dayton',
                link: '',
                isFavorite: false,
                beds: '3',
                baths: '2',
                sqft: '1,667',
                price: 'PRICE TBD'
              }
            ]
          },
          {
            communityName: 'Community Name',
            categoryId: 3,
            items: []
          },
          {
            communityName: 'Community Name',
            categoryId: 4,
            items: []
          }
        ];

        $scope.$on('msTabs::tab-changed', function(_, index) {
          $timeout(function() {
            $scope.selectedCategoryId = index + 1;
          }, 0);
        });

      }]);

})();
