(function() {


  'use strict';
  /* global angular, ScrollMagic, TweenMax */

  angular.module("Milestone").directive('parallaxMs', function() {
    return function(scope, elem, attrs) {
      var controller = new ScrollMagic.Controller();
      var tween = TweenMax.to(elem, 100, {
        top: elem.data('top')
      });

      new ScrollMagic.Scene({
        triggerElement: elem.data('target'),
        duration: elem.data('duration')
      })
        .setTween(tween)
        //.addIndicators()
        .addTo(controller);
    };
  });

})();
