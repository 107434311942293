(function() {
  'use strict';
  /* global $, angular */

  angular
    .module("Milestone")
    .controller("errorsCtrl", ["$scope", "$rootScope", "scrollMagic",
      function($scope, $rootScope, scrollMagic) {
        
      }
    ])
  ;
})();
