angular
  .module("Milestone")

  .service('RegionDataService', ['$http', '$q', 'RegionDataCache',
    function($http, $q, RegionDataCache) {
      'use strict';

      return {
        'region': function(id) {
          return $q(function(resolve) {
            resolve(RegionDataCache.region);
          });
        }
      };

    }
  ])

;