(function() {
  'use strict';
  /* global $, angular */
  angular
    .module("Milestone")
    .directive('aspectResize', ['device', function(device) {
      return function(scope, elem, attrs) {

          $(window).on('load resize', function(){
            var w = elem.width();
            elem.height(w * (9/16));

          });

      };
    }]);
})();