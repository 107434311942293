;(function(){

  'use strict';
  /* global angular */

  angular.module("Milestone.DataServices")

    .service("ComingSoonCommunitiesListDataService", ["$http", function($http){
      var data = {
        regions: [],
        communities: []
      };

      // Go fetch the regions from the API
      $http.get('/coming-soon-communities')
        .then(function(result){
          angular.forEach(result.data.regions, function(r){
            data.regions.push(r);
            angular.forEach(r.communities, function(c){
                c.region_seo_name = r.seo_name;

                // set up special cases for coming soon page
                c.extras = false;
                c.extras_url = '';
                c.extras_message = '';

                switch (c.id) {
                    case 12:
                        c.extras = true;
                        c.extras_url = 'http://thegriffinaustin.com/';
                        c.extras_message = 'Find out more information';
                        break;
                    case 25:
                        c.extras = true;
                        c.extras_url = 'http://1306west.com/';
                        c.extras_message = 'Find out more information';
                        break;
                    default:
                        break;
                }

                data.communities.push(c);
            });
          });
        });

      return {
        regions: data.regions,
        communities: data.communities
      };
    }])

  ;

})();
