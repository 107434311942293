(function() {
    'use strict';

    const Popup = angular.module('Milestone.Popup', []);

    const PopupController = (function() {
        const popup = function($timeout, $window) {
            this.$timeout = $timeout;
            this.$window = $window;

            this._expires = this.expires ? new this.$window.Date(this.expires) : null;
            this._delay = this.delay ? parseInt(this.delay, 10) : 0;
            this._reminder = this.reminder ? parseInt(this.reminder, 10) : 0;

            this.isOpen = false;
            this.now = this.$window.Date.now();
            this.isRemindable = true;
            this.hasUserClosed = false;
            this.displayTimeout = undefined;
            this.unbindWatch = undefined;
            this.isExpired = this.checkExpiry();

            this.display = this.display.bind(this);
            this.handleKeyup = this.handleKeyup.bind(this);
        };

        // Angular lifecycle hooks
        popup.prototype.$onInit = function () {
            if (!this.isExpired) {
                this.checkReminders();
            }
        };
        popup.prototype.$postLink = function () {
            if (!this.isExpired && this.isRemindable) {
                this.displayTimeout = this.$timeout(this.display, (this._delay * 1000));
            }
        };
        popup.prototype.$onDestroy = function () {
            if (this.displayTimeout) {
                this.$timeout.cancel(this.displayTimeout);
            }
        };

        popup.prototype.checkExpiry = function() {
            return this._expires ? this.now >= this._expires.getTime() : false;
        };

        popup.prototype.close = function () {
            this.$window.removeEventListener('keyup', this.handleKeyup);
            this.isOpen = false;
        };

        popup.prototype.display = function() {
            this.isOpen = true;
            this.initListeners();
        };

        popup.prototype.checkReminders = function() {
            const lastClosed = this.$window.localStorage.getItem('popup_last_closed');

            if (lastClosed) {
                const daysAgo = (this.now - lastClosed) / (1000 * 3600 * 24);
                if (daysAgo <= this._reminder) {
                    this.isRemindable = false;
                }
            }
        };

        popup.prototype.handleKeyup = function(e) {
            if (e.key === 'Escape') {
                this.handleClose();
            }
        },

        popup.prototype.initListeners = function() {
            this.$window.addEventListener('keyup', this.handleKeyup, false);
        };

        popup.prototype.handleClose = function(actionUrl) {
            this.$window.localStorage.setItem('popup_last_closed', this.$window.Date.now());

            if (actionUrl) {
                this.$window.open(actionUrl, '_blank');
            }

            this.close();
        };

        return popup;
    })();

    PopupController.$inject = ['$timeout', '$window'];

    const popupDirective = function() {
        return {
            restrict: 'A',
            bindToController: {
                expires: '@',
                delay: '@',
                reminder: '@',
                actionUrl: '@'
            },
            controller: PopupController,
            controllerAs: 'vmPopup'
        };
    };

    Popup.directive('popupDirective', popupDirective);
})();
