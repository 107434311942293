(function() {


  'use strict';
  /* global angular */

  angular.module('Milestone')
    .directive('msTabs', ['$timeout', function($timeout) {

      return {
        templateUrl: '/ms-tabs',
        restrict: 'E',
        scope: {},
        replace: true,
        transclude: true,
        link: function(scope, element, attrs) {

          var tabs,
            selectedTab = 0;

          if (attrs.selectedTab) {
            selectedTab = parseInt(attrs.selectedTab, 10);
          }

          $timeout(function() {
            tabs = element[0].querySelectorAll('.ms-tabs-header .ms-tabs-header-item');
            angular.element(tabs[selectedTab - 1]).addClass('active');

            for (var i = 0, l = tabs.length; i < l; i += 1) {
              tabs[i].addEventListener('click', onClickHandler);
              tabs[i].setAttribute('data-index', i);
            }
          }, 0);

          function onClickHandler(e) {
            e.preventDefault();

            var el = angular.element(e.currentTarget);

            for (var i = 0, l = tabs.length; i < l; i += 1) {
              angular.element(tabs[i]).removeClass('active');
            }

            el.addClass('active');
            scope.$emit('msTabs::tab-changed', parseInt(el.attr('data-index'), 10));
          }

        }
      };

    }])
  ;

})();
