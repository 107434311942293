(function() {

  'use strict';
  /* global angular */

  angular.module('Milestone')
    .directive('msAccordion', ['$timeout', function($timeout) {

      return {
        templateUrl: '/ms-accordion',
        restrict: 'E',
        replace: true,
        transclude: true,
        scope: {
          bindTo: '='
        },
        link: function(scope, element, attrs) {

          scope.$watch('bindTo', function(newValue, oldValue) {
            if (newValue !== oldValue) {
              init();
            }
          });

          init();

          function init() {
            $timeout(function() {
              var items = element[0].querySelectorAll('.ms-accordion-item .ms-accordion-item-header');
              for (var i = 0, l = items.length; i < l; i += 1) {
                items[i].addEventListener('click', onClickHandler);
              }
            }, 0);
          }

          function onClickHandler(e) {
            e.preventDefault();

            var el = angular.element(e.currentTarget).parent();
            var hasItems = el.hasClass('has-items');

            if (hasItems) {
              if (el.hasClass('active')) {
                el.removeClass('active');
              } else {
                el.addClass('active');
              }
            }
          }

        }
      };

    }]);

})();