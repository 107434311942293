(function() {
  'use strict';
  /* global angular */

  angular
    .module("Milestone.microsite")

    .service("FloorPlanDataService", ['MicrositeDataCache', function(MicrositeDataCache) {

      var data = {

        availableHomes: MicrositeDataCache.community.homes,

        homeWeek: (MicrositeDataCache.community.homeOfTheWeek) ? [MicrositeDataCache.community.homeOfTheWeek] : false,

        homeCards: MicrositeDataCache.community.residences
      };

      return {
        data: data
      };

    }])
  ;

})();
