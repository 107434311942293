;(function() {

  'use strict';
  /* global angular */

  angular
    .module("Milestone.DataServices")

    .service('CommunityService', ['MicrositeDataCache', function(MicrositeDataCache) {

      var data = {};

      if (MicrositeDataCache !== undefined) {
        data.community = MicrositeDataCache.community;
      }

      return {
        data: data.community
      };

    }])
  ;

})();