;(function(){

  'use strict';
  /* global angular */

  angular.module("Milestone.DataServices")

    .service("CommunitiesListDataService", ["$http", function($http){
      var data = {
        regions: [],
        communities: []
      };

      // Go fetch the regions from the API
      $http.get('/regions')
        .then(function(result){
          angular.forEach(result.data.regions, function(r){
            data.regions.push(r);
            angular.forEach(r.communities, function(c){
            	c.region_seo_name = r.seo_name;

            	data.communities.push(c);
          	});
          });
        });

      return {
        regions: data.regions,
        communities: data.communities
      };
    }])

  ;

})();
