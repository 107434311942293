(function() {

  'use strict';
  /* global $, angular */

  angular.module("Milestone").controller("privacyPolicyCtrl", ["$scope",
    function($scope) {

      //

    }
  ]);

})();
