angular
  .module("Milestone")

  .controller("MySavedHomesRecentlyViewedCtrl", ["$scope", "$timeout",
    function($scope, $timeout) {
      'use strict';

      $scope.regions = _SavedHomesDataCache.regions;
      $scope.communities = _.toArray(_SavedHomesDataCache.communities);

      if ($scope.regions.length > 0) {
        $scope.selectedCategoryId = $scope.regions[0].id;
        $scope.selectedCategoryName = $scope.regions[0].name;
      }

      $scope.selectCategory = function(region_id) {
        $scope.selectedCategoryId = region_id;
      };

      $scope.recentlyViewedCategories = [
        {
          id: 1,
          name: 'Downtown / South Austin'
        },
        {
          id: 2,
          name: 'Hill Country'
        }
      ];
      $scope.recentlyViewed = [
        {
          communityName: 'Community Name',
          categoryId: 1,
          items: [
            {
              image: '../images/site/elevation.png',
              name: 'Dayton',
              link: '',
              beds: '3',
              baths: '2',
              sqft: '1,667',
              price: 'PRICE TBD'
            }
          ]
        },
        {
          communityName: 'Community Name',
          categoryId: 1,
          items: []
        },
        {
          communityName: 'Community Name',
          categoryId: 1,
          items: []
        },
        {
          communityName: 'Community Name',
          categoryId: 1,
          items: []
        },
        {
          communityName: 'Community Name',
          categoryId: 1,
          items: []
        },
        {
          communityName: 'Community Name',
          categoryId: 2,
          items: [
            {
              image: '../images/site/elevation.png',
              name: 'Dayton',
              link: '',
              beds: '3',
              baths: '2',
              sqft: '1,667',
              price: 'PRICE TBD'
            }
          ]
        }
      ];

      $scope.$on('msTabs::tab-changed', function(_, index) {
        $timeout(function() {
          $scope.selectedCategoryId = index + 1;
        }, 0);
      });

    }])
;
