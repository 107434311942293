(function() {

  'use strict';
  /* global angular, _RegionsDataCache */

  angular
    .module("Milestone")

    .service('RegionsDataCache', function() {

      var data = {
        regions: null
      };

      if (typeof _RegionsDataCache !== 'undefined') {
        data.regions = _RegionsDataCache.regions;
      }

      return {
        regions: data.regions
      };

    })

  ;

})();
