;(function() {

  'use strict';
  /* global $, angular */

  angular
    .module("Milestone")

    .controller("CarouselCtrl", ['$scope',
      function($scope) {

        $('.carousel').carousel({
          //interval: 7000
        });


        // This belongs in a directive -wo
        $('.carousel').on('slid.bs.carousel', function(e) {
          $(".caption").removeClass("slide-up");
          $(".item.active .caption").addClass("slide-up");
        });

        $(document).ready(function() {
          $('.item .fill').velocity('fadeIn', 1000, function() {
            $(".item.active .caption").addClass("slide-up");
          });
        });

        $scope.swipeLeft = function(){
          console.log("swiped next");
          $('.carousel').carousel('next');
        }

        $scope.swipeRight = function(){
          console.log("swiped prev");
          $('.carousel').carousel('prev');
        }

      }
    ])
  ;

})();
