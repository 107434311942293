(function() {

  'use strict';
  /* global angular */

  angular
    .module("Milestone")

    .service('RegionsDataService', ['RegionsDataCache', '$http', '$q', function(RegionsDataCache, $http, $q) {

      var data = {};

      if (RegionsDataCache.regions) {
        data.regions = $q.promise(function(resolve){
          resolve(RegionsDataCache.regions);
        });
      } else {
        // need to implement this
        data.regions = $http.get('/regions')
          .then(function(res){
            return res.data.regions;
          });
      }

      console.log(data.regions);
      return {
        regions: data.regions
      };

    }])

  ;

})();