(function() {

  'use strict';
  /* global $, angular */

  angular.module("Milestone.Scrollspy", []);

  angular
    .module("Milestone.Scrollspy")

    .directive('scrollspy', ['$location', 'device', '$timeout', function($location, device, $timeout) {

      return function(scope, elem, attrs) {
        return;

        //SCROLL SPY
        $('body').scrollspy({
          target: "#microsite-secondary",
          offset: 217
        });

        var scrollToTarget = function(target) {

          var mobileOffset = 0;
          if(device.check() == "phone"){
            mobileOffset = 60;
            if(target === '#welcome'){
              mobileOffset = -50;
            }
          }

          var offset = 64;
          if (target === '#welcome') {
            offset = 150;
          }

          var scrollTo = $(target).offset().top - offset - mobileOffset;
          if (target === '#micrositeCarousel') {
            scrollTo = 0;
          }

          $location.url(target.substring(1));

          $('html, body').animate({
            scrollTop: scrollTo
          }, 1000);
        };

        //SCROLL TO
        elem.find('a').on('click', function(event) {
          console.log("this trigger");
          event.preventDefault();

          var target = $(this).attr('href');

          // Floorplans and Available Homes are tabs on the same 
          // scroll anchor, so just switch tabs instead of scroll.
          if (target === '#floorplans') {
            $('.li-floorplans.two').removeClass('active');
            $('#floorplans .nav-tabs .item-floor-plans').click();
          } else if (target === '#available-homes') {
            $('.li-floorplans.one').removeClass('active');
            $('#floorplans .nav-tabs .item-available-homes').click();
          }

          //if is mobile close dropdown after click
          if(device.check() == "phone"){
            $(this).closest('.container').find('button').click();
          }

          scrollToTarget(target);
        });

        //SCROLLSPY EVENT
        $('#microsite-secondary').on('activate.bs.scrollspy', function() {
          scope.$apply(function() {
            // var uri = elem.find('li.active a').attr('href');
            // $location.path(uri.substring(1), false);
          });
        });

      };

    }]);

})();
