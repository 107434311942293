(function() {

  'use strict';
  /* global angular */

  angular.module("Milestone").directive('ellipsis', function() {
    var i = 0;
    return function(scope, elem, attrs) {

      /*minLimit: starts truncation range"*/
      var minLength = attrs.limitMin;
      var maxLength = attrs.limitMax;

      /*gives vartiation to the height of each text that is truncated on even elements ex: set stagger='true'*/
      if (i % 2 === 0 && attrs.stagger) {
        maxLength = maxLength * 0.65;
      }
      i++;

      /*this keeps the original content display post truncation*/
      var dataString = attrs.content;


      if (dataString.length > minLength) {
        var trimmedString = dataString.substr(0, maxLength);
        trimmedString = trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" ")));
        elem.html(trimmedString + "...");
      } else {
        elem.html(dataString);
      }

    };
  });

})();
