(function() {

  'use strict';
  /* global angular */

  angular.module("Milestone").directive('readMore', function() {
    return {
      restrict: 'A',
      transclude: true,
      replace: true,
      template: '<p></p>',
      scope: {
        moreText: '@',
        lessText: '@',
        words: '@',
        ellipsisIndicator: '@',
        char: '@',
        limit: '@',
        content: '@'
      },
      link: function(scope, elem, attr, ctrl, transclude) {
        var moreText = angular.isUndefined(scope.moreText) ? ' <a class="read-more">Read More...</a>' : ' <a class="read-more">' + scope.moreText + '</a>',
          lessText = angular.isUndefined(scope.lessText) ? ' <a class="read-less">Less ^</a>' : ' <a class="read-less">' + scope.lessText + '</a>',
          ellipsisIndicator = angular.isUndefined(scope.ellipsisIndicator) ? '' : scope.ellipsisIndicator,
          limit = angular.isUndefined(scope.limit) ? 150 : scope.limit;
  
        attr.$observe('content', function(str) {
          readmore(str);
        });
  
        transclude(scope.$parent, function(clone, scope) {
          readmore(clone.text().trim());
        });
  
        function readmore(text) {
  
          var text = text.replace(/(<([^>]+)>)/ig,""),
            orig = text,
            regex = /\s+/gi,
            charCount = text.length,
            wordCount = text.trim().replace(regex, ' ').split(' ').length,
            countBy = 'char',
            count = charCount,
            foundWords = [],
            markup = text,
            more = '';
  
          if (!angular.isUndefined(attr.words)) {
            countBy = 'words';
            count = wordCount;
          }
  
          if (countBy === 'words') { // Count words
  
            foundWords = text.split(/\s+/);
  
            if (foundWords.length > limit) {
              text = foundWords.slice(0, limit).join(' ') + '<span class="ellipsis-indicator">' + ellipsisIndicator + '</span>';
              more = foundWords.slice(limit, count).join(' ');
              markup = text + moreText + '<span class="more-text">' + more + lessText + '</span>';
            }
  
          } else { // Count characters
  
            if (count > limit) {
              text = orig.slice(0, limit) + '<span class="ellipsis-indicator">' + ellipsisIndicator + '</span>';
              more = orig.slice(limit, count);
              markup = text + moreText + '<span class="more-text">' + more + lessText + '</span>';
            }
  
          }
  
          elem.append(markup);
          elem.find('.read-more').on('click', function() {
            $(this).prev('.ellipsis-indicator').hide();
            $(this).hide();
            elem.find('.more-text').addClass('show').slideDown();
            
            // hack
            var row_height = $(this).closest('.row').outerHeight();
            $(this).closest('.simple-fade').height(row_height);
          });
          elem.find('.read-less').on('click', function() {
            elem.find('.read-more').prev('.ellipsis-indicator').show();
            elem.find('.read-more').show();
            elem.find('.more-text').hide().removeClass('show');
            
            // hack
            var row_height = $(this).closest('.row').outerHeight();
            $(this).closest('.simple-fade').height(row_height);
          });
  
        }
      }
    };
  });

})();
