(function() {

  'use strict';
  /* global $, angular */

  angular
    .module("common")

    .filter('lotPrice', ['$filter', function($filter) {
      return function(input) {
        var price = input;

        if (1 * price === 0) {
          return "Price TBD";
        }

        return $filter('currency')(price, '$', 0);
      };
    }])

    .directive('lotPrice', ['$filter', function($filter) {
      return {
        link: function(scope, elem, attrs) {

          elem = $(elem);

          // strip everything that is not a number or .
          var content = elem.html().replace(/[\$\,\sa-zA-Z]/g, '');
          console.log(content);

          var new_content = $filter('lotPrice')(content);
          elem.html(new_content);
          return;
        }
      };
    }])
  ;

})();