(function() {

  'use strict';
  /* global angular */

  angular.module("Milestone").directive('mobileMaxHeight', ['device', function(device) {
    return function(scope, elem, attrs) {

    	$(window).load(resizeHeight);
    	$(window).resize(resizeHeight);

    	function resizeHeight(){
        if(device.check() == 'phone'){      
            var navHeight = $('.mobile-navbar-header').outerHeight();
            var footerHeight = $('.footer-mobile').height();
            var maxHeight = $(window).height() -  navHeight - footerHeight;

            $(elem).css({
              "max-height" : maxHeight,
              "overflow"   : "auto"
            });
            
        }
    	}

    };
  }]);

})();
