(function() {

    'use strict';
    /* global $, angular */

    angular.module('Milestone.Forms')
        .directive('msCommunityDropdown', function() {
            return {
                restrict: 'E',
                scope: {
                    text: '@',
                    checkboxPrefix: '@',
                    selectedCommunities: '=ngModel',
                    regions: '=',
                    singleSelect: '='
                },
                templateUrl: 'ms-community-dropdown.html',
                link: function($scope) {

                    // Prevent dropdown to be closed when we click on an accordion link
                    $('.community-drop-down .dropdown-accordion').on('click', 'a.accordion-toggle', function(event) {
                        event.preventDefault();
                        event.stopPropagation();
                    });

                    // Prevent dropdown from closing when click on checkbox
                    $('.community-drop-down .dropdown-accordion').on('click', 'ul.checkboxes', function(event) {
                        event.stopPropagation();
                    });

                    // Prevent page scroll while inside of accordion
                    $('.community-drop-down .dropdown-accordion').bind('mousewheel DOMMouseScroll', function(e) {
                        var scrollTo = null;

                        if (e.type == 'mousewheel') {
                            scrollTo = (e.originalEvent.wheelDelta * -1);
                        }
                        else if (e.type == 'DOMMouseScroll') {
                            scrollTo = 40 * e.originalEvent.detail;
                        }

                        if (scrollTo) {
                            e.preventDefault();
                            $(this).scrollTop(scrollTo + $(this).scrollTop());
                        }
                    });

                },
                controller: ["$scope", function($scope) {

                    $scope.labels = {
                        community: $scope.text
                    };
                    $scope.selectedCommunities = [];

                    // toggle community selections
                    $scope.toggleCommunitySelection = function toggleCommunitySelection(id) {
                        var idx = $scope.selectedCommunities.indexOf(id);
                        // is currently selected
                        if (idx > -1) {
                            $scope.selectedCommunities.splice(idx, 1);
                        }
                        // is newly selected
                        else {
                            $scope.selectedCommunities.push(id);
                        }

                        // Update the dropdown label
                        updateLabel(false);
                    };

                    // slect community for single-select mode
                    $scope.selectCommunity = function selectCommunity(community) {
                        $scope.selectedCommunities = [];
                        $scope.selectedCommunities.push(community.id);
                        updateLabel(community.name);
                    };

                    var updateLabel = function updateLabel(name) {
                        var size = $scope.selectedCommunities.length;

                        // single-select mode
                        if (name) {
                            $scope.labels.community = size == 0 ? $scope.text : name + " selected";
                        } else {
                            // multi-select mode
                            switch (size) {
                                case 0:
                                    $scope.labels.community = $scope.text;
                                    break;
                                case 1:
                                    $scope.labels.community = size + ' community selected';
                                    break;
                                default:
                                    $scope.labels.community = size + ' communities selected';
                                    break;
                            }
                        }
                    };

                }]
            };
        });

})();